import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Theme from '../style/theme';
import people from '../data/people';
import Accordion from '../components/Accordion';
import Breadcrumbs from '../components/Breadcrumbs';

const PageWrapper = styled.main`
  background: ${Theme.backgroundWhite};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  h1 {
    color: ${Theme.primary};
  }

  .para {
    font-size: 1rem;
    text-align: justify;
    max-width: 30rem;
    margin: 0.75rem;
    color: ${Theme.black};
  }
`

const People = (props) => {
  const { breadcrumbs } = props;
  const admins = people.filter(p => p.role === "admin");
  const donors = people.filter(p => p.role === "donor");
  const faculty = people.filter(p => p.role === "faculty");
  const students = people.filter(p => p.role === "student");
  const others = people.filter(p => p.role === "other");

  const accordionData = [
    {
      title: "Administrators",
      data: admins,
    },
    {
      title: "Donors",
      data: donors,
    },
    {
      title: "Faculty",
      data: faculty,
    },
    {
      title: "Students",
      data: students,
    },
    {
      title: "Other",
      data: others,
    },
  ]

  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  
  return (
    <PageWrapper>
      <Helmet>
        <title>Pepperdine People in 1972</title>
        <meta name="description" content="Discover the people behind the Malibu miracle: from university president William S. Banowsky to cheerleader Karen Bua. Get a taste of what Pepperdine people were like in 1972." />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Pepperdine People in 1972"
        />
        <meta
          name="twitter:description"
          content="Discover the people behind the Malibu miracle: from university president William S. Banowsky to cheerleader Karen Bua. Get a taste of what Pepperdine people were like in 1972."
        />
        <meta name="twitter:image" content={"https://www.quinquagenary1972.com/50512.png"} />
        <meta name="twitter:image:alt" content="" />
        <meta
          property="og:title"
          content="Pepperdine People in 1972"
        />
        <meta
          property="og:description"
          content="Discover the people behind the Malibu miracle: from university president William S. Banowsky to cheerleader Karen Bua. Get a taste of what Pepperdine people were like in 1972."
        />
        <meta property="og:image" content={"https://www.quinquagenary1972.com/50512.png"} />
      </Helmet>
      <Breadcrumbs pages={breadcrumbs} />
      <h1>People</h1>
      <p className="para">Discover the people behind the Malibu miracle: from university president William S. Banowsky to cheerleader Karen Bua. Get a taste of what Pepperdine people were like in 1972.</p>
      <Accordion data={accordionData} />
    </PageWrapper>
  )
}

export default People;
