import React from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import Theme from '../style/theme';

const PageWrapper = styled.div`
  background: ${Theme.backgroundWhite};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 1rem;

  .form {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  button {
    background-color: ${Theme.primary};
  }
  .searchIcon {
    color: ${Theme.white};
  }
`;

const Search = props => {
  let { query } = props;

  if (!query) {
    query = "";
  }

  return (
    <PageWrapper>
      {query && <h1>Showing results for: '{query}'</h1>}
      <form action="/search/" method="get">
        <label htmlFor="omnisearch">Search&nbsp;</label>
          <input
            id="omnisearch"
            name="tag"
            type="text"
            placeholder="Name, location, topic"
          />
          <button type="submit">
            <span className="searchIcon"><FaSearch /></span>
          </button>
      </form>
    </PageWrapper>
  )
}

export default Search;