import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { monthList } from '../utilities';
import Theme from '../style/theme';

const FilterBarWrapper = styled.form`
  width: 100vw;
  background-color: ${Theme.mediumGray};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
`

const FilterBar = props => {
  const {
    eventCount,
    setLocationFilter,
    setMonthFilter,
    setTagFilter,
    clearFilters,
    tags,
    locations,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const queryTag = searchParams.get('tag');
  const navigate = useNavigate();

  const resetForm = e => {
    e.preventDefault();
    if (queryTag != null) {
      // the filter being applied is from search params, so clear it by navigating back to the main timeline page
      navigate('/timeline');
    }
    
    clearFilters();

    document.getElementById("filter-form").reset();
  }

  return (
    <FilterBarWrapper id="filter-form">
      <label htmlFor="tag" onChange={e => setTagFilter(e.target.value)}>
        Filter by Tag:&nbsp;
        <select name="tag">
          <option value="">Select tag...</option>
          {
            tags.map(t => (
                <option value={t} key={t}>
                  {t}
                </option>))
          }
        </select>
      </label>
      <label htmlFor="location" onChange={e => setLocationFilter(e.target.value)}>
        Filter by Location:&nbsp;
        <select name="location">
          <option value="">Select location...</option>
          {
            locations.map(t => (
                <option value={t} key={t}>
                  {t}
                </option>))
          }
        </select>
      </label>
      <label htmlFor="month" onChange={e => setMonthFilter(e.target.value)}> Filter by Month:&nbsp;
        <select name="month">
          <option value="">Select month...</option>
          {monthList.map((v, i) => <option value={i} key={v}>{v}</option>)}
        </select>
      </label>
      <span>
        {eventCount} events
      </span>
      <button onClick={resetForm}>
        Clear filters
      </button>
    </FilterBarWrapper>
  )
}

export default FilterBar;