import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../style/theme';

const PageWrapper = styled.main`
  background: ${Theme.backgroundWhite};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: ${Theme.primary};

  .redirectLink {
    color: ${Theme.accent};
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const NotFound = props => {
  const { redirectPath, targetPage } = props;
  const navigate = useNavigate();

  const goTo = path => navigate(path);

  return (
    <PageWrapper>
      <Helmet>
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="404: Not Found!"
        />
        <meta
          name="twitter:description"
          content="This page was not found!"
        />
        <meta name="twitter:image" content={"https://www.quinquagenary1972.com/50512.png"} />
        <meta name="twitter:image:alt" content="" />
        <meta
          property="og:title"
          content="404: Not Found!"
        />
        <meta
          property="og:description"
          content="This page was not found!"
        />
        <meta property="og:image" content={"https://www.quinquagenary1972.com/50512.png"} />
      </Helmet>
      <h1>Oops!</h1>
      <p>We couldn't find what you're looking for.&nbsp;
        <span
          className="redirectLink" 
          onClick={() => goTo(`/${redirectPath ?? ""}`)}
        >
          Go {targetPage ?? "Home"}
        </span>.
      </p>
    </PageWrapper>
  );
}

export default NotFound;
