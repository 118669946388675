import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';

const IconWrapper = styled.span`
  color: ${props => props.color};
  font-size: 1rem;
  margin: 0  2px;
  position: relative;
  display: inline-block;

  @media only screen and (min-width: 700px) {
    &:hover {
      font-size:1.05rem;
    }
  }
  
  &:hover .hoverCard {
    visibility: visible;
  }

  .hoverCard {
    visibility: hidden;
    position: absolute;
    top: 105%;
    right: 12px;
    align-items: center;
    height: 1.5rem;
    font-size: 0.85rem;
    z-index: 1;
    background: white;
    padding: 0.2rem 0.25rem;
    box-shadow: ${Theme.boxShadow};
    white-space: nowrap;
    color: ${Theme.lightGray};
  }
`

const HoverIcon = props => {
  const { icon, hoverText, color, className } = props;

  return (
    <IconWrapper color={color} className={className}>
      {icon}
      <span className="hoverCard">{hoverText}</span>
    </IconWrapper>
  )
}

export default HoverIcon;
