import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';

const TickWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 16.5rem;
  background-color: ${Theme.backgroundWhite};

  .tick {
    width: 1.5rem;
    height: 0.25rem;
    margin-left: 0.25rem;
    background-color: ${Theme.primary};
  }
  .line {
    width: 0.5rem;
    height: 0.625rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    background-color: ${Theme.primary};
  }
  .left {
    padding-left: 0.5rem;
  }
  .date {
    font-size: 1rem;
    color: ${Theme.black};
    padding-left: 4px;
  }
`;

const Tick = props => {
  const { date, month, day, first, paddingTop } = props;
  const getPadding = () => {
    return paddingTop + 0.625 || 0;
  }

  const formatDay = dateString => {
    if (dateString.slice(8, 10) == "01") { // 01
      return dateString.slice(3, 8) + dateString.slice(9,10);
    } else if (Number(dateString.slice(8,10)) < 10) { // 02 - 09
      if (first) {
        return dateString.slice(3, 10);
      } else {
        return dateString.slice(9, 10);
      }
    } else {
      if (first) {
        return dateString.slice(3,10);
      } else {
        return dateString.slice(8, 10);
      }
    }
  }

  return (
    <TickWrapper>
      <div className="left">
        <div className="line" style={{height:`${getPadding()}rem`}} />
        <div className="tick" />
        <div className="line" />
      </div>
      <div className="date">
        {day
          ? formatDay(day.toDateString())
          : month
            ? month.toDateString().slice(3,7)
            : date.toDateString().slice(11)}
      </div>
    </TickWrapper>
  );
}

export default Tick;
