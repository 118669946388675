import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';

const CompactWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 16rem;
  height: 3.75rem;
  z-index: 2;
  background: ${Theme.pLightBlue};
  margin: 0.25rem;
  padding: 0.25rem 0.25rem 0.25rem 0;
  border-radius: 3.75rem;
  cursor: pointer;

  @media only screen and (min-width: 700px) {
    &:hover { 
      background-color: ${Theme.accent};
      box-shadow: 1.5px 1.5px 5px #557788;
  
      .position {
        color: ${Theme.primary};
      }
  
      .name {
        color: ${Theme.primary};
      }
    }
  }

  &:active {
    box-shadow: inset 1.5px 1.5px 3px ${Theme.primary};
  }

  .vitals {
    font-size: 1rem;
    color: ${Theme.black};
    display: flex;
    flex-flow: column nowrap;
    line-height: 1rem;
    max-height: 3rem;
    overflow: clip;
    text-overflow: ellipsis;
    text-decoration: none;
  }

  .bigInitials {
    border-radius: 50%;
    width: 3.25rem;
    height: 3.25rem;
    text-align: center;
    line-height: 3.25rem;
    background-color: ${props => props.gender === "M" ? "#00205c" : "#c25700"};
    color: ${Theme.white};
    margin: 0.5rem;
    cursor: pointer;
    text-decoration: none;
  }

  .bigger {
    border-radius: 50%;
    height: 3.25rem;
    width: 3.25rem;
    margin: 0.5rem;
    cursor: pointer;
    text-decoration: none;
  }

  .name {
    color: ${Theme.black};
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }

  .position {
    color: ${Theme.primary};
    text-decoration: none;
  }
`;

const CompactPerson = props => {
  const { photo, name, gender, initials, position } = props.person;

  let bigger;
  if (photo === null || !Theme.showPictures) {
    bigger = (<div className="bigInitials">{initials}</div>)
  } else {
    bigger = (<img src={photo.path} alt={name} className="bigger" />)
  }

  return (
    <a href={"/people/" + name} style={{textDecoration: "none"}}>
      <CompactWrapper gender={gender}>
        {bigger}
        <div className="vitals">
          <p className="name" >{name}</p>
          <p className="position">{position}</p>
        </div>
      </CompactWrapper>
    </a>
  )
}

export default CompactPerson;
