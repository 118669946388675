import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';
import Sources from './Sources';

const DescWrapper = styled.div`
  .para {
    font-size: 1rem;
    text-align: justify;
    max-width: 30rem;
    margin: 0.75rem;
    color: ${Theme.black};
  }

  sup {
    line-height: 0;
    font-weight: bold;

    /* The following rules (or similar) likely come from browser 
     * style may not be needed
     */
    font-size: 0.83em;
    vertical-align: super;
  }

  h4 {
    color: ${Theme.black};
  }
`

const Description = props => {
  const { text, sources } = props.description;

  const re_super = /(<\d*>)/gi
  const re_ital = /(\|[a-zA-Z\s&-]*\|)/gi
  const formatSuperscript = p => 
    p.split(re_super).map((part, i) => re_super.test(part)
          ? (<sup key={i}>{part.slice(1, part.length - 1)}</sup>)
          : formatItalics(part));

  const formatItalics = p => 
    p.split(re_ital).map((part, i) => re_ital.test(part)
        ? (<em key={i}>{part.slice(1, part.length - 1)}</em>)
        : part);

  const descriptionToRender = text
    .split('\n')
    .map(p => (
      <p key={p} className='para'>
        {formatSuperscript(p)}
      </p>))

  return (
    <DescWrapper>
      {descriptionToRender}
      <Sources sources={sources} />
    </DescWrapper>
  )
}

export default Description;
