import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';

const ProfileWrapper = styled.div`
  position: relative;

  .profile {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    margin: 0.5rem;
    box-shadow: ${Theme.boxShadow};
  }

  &:hover .hoverCard {
    visibility: visible;
  }

  .hoverCard {
    visibility: hidden;
    position: absolute;
    top: 105%;
    right: 12px;
    align-items: center;
    /* height: 1.5rem; */
    font-size: 0.85rem;
    z-index: 1;
    background: white;
    padding: 0.2rem 0.25rem;
    box-shadow: ${Theme.boxShadow};
    /* white-space: nowrap; */
    color: ${Theme.lightGray};
  }
`

const ProfilePicture = props => {
  const { photo, name } = props;
  const { path, caption, source } = photo;
  const { url, title, date, page, publisher } = source;

  const getHoverText = () => {
    return `"${title}," ${date}${page === "" ? "" : ", p. "}${page} (${publisher})`;
  }

  return (
    <ProfileWrapper>
      <a
        href={url ?? ""}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={path}
          className='profile'
          alt={caption ? caption : name}
        />
      </a>
      <span className="hoverCard">{getHoverText()}</span>
    </ProfileWrapper>
  )
}

export default ProfilePicture;
