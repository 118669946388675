import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../style/theme';
import Description from './Description';
import SeeAlso from './SeeAlso';
import CaptionedPhoto from './CaptionedPhoto';
import HoverIcon from './HoverIcon';
import { FaMapMarkerAlt, FaRegCalendarAlt } from 'react-icons/fa';
import TagChip from './TagChip';

const FocusWrapper = styled.div`
  width: 25rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.6);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 1;

  .modal {
    position: relative;
    margin: 1.5rem auto;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    width: 90%;
    max-width: 40rem;
    max-height: 90vh;
    border: 1px solid ${Theme.black};
    border-radius: ${Theme.borderRadius};
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .x {
    position: absolute;
    top: 0;
    right: 0;
    // transform-origin: 4px 4px;
    padding: 1rem;
    cursor: pointer;

    .x1 {
      padding: 0;
      margin: 0;
      width: 1rem;
      height: 2px;
      background-color: ${Theme.mediumGray};
      display: block;
      transform: rotate(45deg);
    }

    .x2 {
      padding: 0;
      margin: -2px 0;
      width: 1rem;
      height: 2px;
      background-color: ${Theme.mediumGray};
      display: block;
      transform: rotate(-45deg);
    }
  }

  h2 a {
    display: block;
    color: ${Theme.pBlue};
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.75rem;
    cursor: pointer;

    @media only screen and (min-width: 700px) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .details {
    color: ${Theme.black};
    font-size: 1rem;
    margin: 0.75rem;

  }

  .sourceLink {
    color: ${Theme.pBlue};
    text-decoration: none;
    
    span {
      color: ${Theme.black};
    }
  }

  .flexRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.5rem;
  }

  .tags {
    margin-top: 4px;
    margin-right: 4px;
    font-weight: bold;
  }

  .tag {
    height: 2rem;
    padding: 4px;
    font-size: ${Theme.fontSize};
    background-color: ${Theme.pBlue};
    color: ${Theme.white};
    border-radius: ${Theme.borderRadius};
    margin-right: 4px;
    cursor: pointer;

    @media only screen and (min-width: 700px) {
      &:hover {
        background-color: ${Theme.accent};
      }
    }
  }

  .heading {
    width: 100%;
    max-width: 30rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  .basement {
    width: 100%;
    max-width: 30rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

const Focus = props => {
  const { id, title, date, location, description, photo, tags } = props.event;
  const navigate = useNavigate();

  const handleFilterByTag = (tag) => {
    props.filterByTag(tag);
    props.setFocus();
  }

  const goToEventPage = () => {
    navigate(`/events/${id}`);
  }

  const formatDateString = str => {
    const days = {
      Sun: "Sunday,",
      Mon: "Monday,",
      Tue: "Tuesday,",
      Wed: "Wednesday,",
      Thu: "Thursday,",
      Fri: "Friday,",
      Sat: "Saturday,"
    };
    const months = {
      Jan: "January",
      Feb: "February",
      Mar: "March",
      Apr: "April",
      May: "May",
      Jun: "June",
      Jul: "July",
      Aug: "August",
      Sep: "September",
      Oct: "October",
      Nov: "November",
      Dec: "December",
    }

    if (str[8] === '0') {
      str = str.slice(0, 8) + str.slice(9);
    }
    const withMonth = str.slice(0,4) + months[str.slice(4,7)] + str.slice(7)
    const withDays = days[withMonth.slice(0,3)] + withMonth.slice(3);
    return withDays.slice(0, withDays.length - 5) + ',' + withDays.slice(withDays.length - 5);
  }

  return (
    <FocusWrapper onClick={props.onClose}>
      <div className="modal" onClick={() => null}>
        <span onClick={props.onClose} className="x">
          <span className="x1" onClick={props.onClose}></span>
          <span className="x2" onClick={props.onClose}></span>
        </span>
        <div className="heading">
          <h2 onClick={goToEventPage}>
            <a href={"/events/" + id}>{title}</a>
          </h2>
          <p className="details">
            <HoverIcon
              icon={<FaRegCalendarAlt />}
              color={Theme.secondary}
              hoverText="date"
            /> {formatDateString(date.toDateString())}
          </p>
          {location &&
            <p className="details">
              <HoverIcon
                icon={<FaMapMarkerAlt />}
                color={Theme.secondary}
                hoverText="location"
              /> {location}
            </p>}
        </div>
        {photo != null && Theme.showPictures && <CaptionedPhoto photo={photo} />}
        <Description description={description} />
        <div className="basement">
          {tags.length > 0 &&
            <div className="flexRow">
              <p className="tags">Tags:</p>
              {tags.map(t => (
                <TagChip data={t} handleFilterByTag={handleFilterByTag} key={t} />
                ))}
            </div>}
          <SeeAlso data={props.event.seeAlso} />
        </div>
      </div>
    </FocusWrapper>)
}

export default Focus;