import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';
import Avatar from './Avatar';
import CompactEvent from './CompactEvent';
import CompactStory from './CompactStory';
import people from '../data/people';
import events from '../data/events';
import stories from '../data/stories';

const SeeAlsoWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 0.5rem;
  max-width: 30rem;
  width: 95%;

  .saSection {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    .saHeader {
      font-weight: bold;
      margin-left: 0.5rem;
    }
  }

  hr {
    width: 90%;
    color: ${Theme.lightGray};
  }
`

const SeeAlso = props => {
  const { data } = props;

  // console.log("data", data);

  const peopleToRender = data.people
    .filter(p => people.find(person => person.name === p))
    .map(p => people.find(person => person.name === p));
  const eventsToRender = data.events
    .filter(e => events.find(event => event.id === e))
    .map(e => events.find(event => event.id === e));
  const storiesToRender = data.stories ? data.stories
    .filter(s => stories.find(story => story.id === s))
    .map(s => stories.find(story => story.id === s))
    : [];

  return (
    <SeeAlsoWrapper>
      <hr />
      {peopleToRender.length + eventsToRender.length + storiesToRender.length > 0 &&
      <h3>See also:</h3>}
      {peopleToRender.length > 0 && 
      <div className="saSection">
        <span className="saHeader">People:&nbsp;</span>
        {peopleToRender.map(p => (
          <Avatar
            person={p}
            key={p.name}
          />))}
      </div>}
      {eventsToRender.length > 0 &&
      <div className="saSection">
        <span className="saHeader">Events:&nbsp;</span>
        {eventsToRender.map(e => (
        <CompactEvent
          event={e}
          key={e.id}
        />))}
      </div>}
      {storiesToRender.length > 0 &&
      <div className="saSection">
        <span className="saHeader">Stories:&nbsp;</span>
        {storiesToRender.map(s => (
          <CompactStory
            story={s}
            key={s.id}
          />))}
      </div>}
    </SeeAlsoWrapper>
  );
}

export default SeeAlso;