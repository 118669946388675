import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../style/theme';

const FootWrapper = styled.footer`
  margin-top: 2rem;
  background: ${Theme.primary};
  height: 10rem;
  width: 100%;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  a, a:visited {
    color: white;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }
  .v-rule {
    width: 1px;
    height: 7rem;
    margin: 0.5rem;
    background-color: ${Theme.secondary};
  }

  .v-wordmark {
    color: ${Theme.accent};
    font-weight: 700;
    font-size: 1.25rem;
    cursor: pointer;
  }
`;

const Footer = props => {
  const navigate = useNavigate();

  return (
  <FootWrapper {...props}>
    <div className="v-wordmark" onClick={() => navigate('/')}>
      <p>QUIN</p>
      <p>QUA</p>
      <p>GEN</p>
      <p>ARY</p>
    </div>
    <div className="v-rule"/>
    <nav>
      <ul>
        <li>
          <Link to="/timeline">Timeline</Link>
        </li>
        <li>
          <Link to="/people">People</Link>
        </li>
        <li>
          <Link to="/stories">Stories</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
      </ul>
    </nav>
  </FootWrapper>);
};

export default Footer;
