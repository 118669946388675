import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../style/theme';
import {ReactComponent as StoryIcon} from '../icons/story.svg';

const StoryWrapper = styled.a`
  background: #cccccc;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 25rem;
  height: 1.5rem;
  cursor: pointer;
  margin: 0.125rem;
  text-decoration: none;

  @media only screen and (min-width: 700px) {
    &:hover {
      background-color: ${Theme.pLightBlue};
    }
  }

  &:active {
    box-shadow: inset ${Theme.boxShadow};
  }
  
  .bar {
    background: ${Theme.accent};
    width: 4px;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .title {
    max-width: 14rem;
    font-size: 0.925rem;
    color: ${Theme.black};
    text-decoration: none;
    padding-right: 0.75rem;
    font-family: monospace;
    font-size: 0.85rem;
    padding-top: 3px;
  }

  .separator {
    color: ${Theme.pBlue};
    margin: 0 0.25rem;
  }

  .storyIcon {
    height: 1rem;
    width: 1rem;
    margin: 0 6px;
  }
`

const CompactStory = props => {
  const { id, title } = props.story;
  const navigate = useNavigate();

  const titleToRender = title.length > 25 ? title.substring(0,22) + '…' : title;

  const goToStory = () => navigate(`/stories/${id}`);

  return (
    <StoryWrapper href={"/stories/" + id} onClick={goToStory}>
      <span className="bar">&nbsp;</span>
      <StoryIcon className="storyIcon" />
      <span className="title">{titleToRender}</span>
    </StoryWrapper>
  )
}

export default CompactStory
