import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';
import {
  FaBookReader,
  FaAppleAlt,
  FaLandmark,
  FaBasketballBall,
  FaUtensils,
  FaCertificate,
  FaChurch,
  FaTools,
  FaMoneyBillWave,
  FaCalendarAlt,
  FaChalkboardTeacher,
  FaHome,
  FaUmbrellaBeach,
  FaNewspaper,
  FaRepublican,
  FaBible,
  FaGraduationCap,
  FaLock,
  FaToilet,
  FaVoteYea,
  FaUserFriends,
  FaPalette
} from 'react-icons/fa';

const ChipWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 2rem;
  padding: 4px 8px;
  font-size: ${Theme.fontSize};
  background-color: ${Theme.pBlue};
  color: ${Theme.white};
  border-radius: ${Theme.borderRadius};
  margin: 2px;
  cursor: pointer;

  @media only screen and (min-width: 700px) {
    &:hover {
      background-color: ${Theme.accent};
    }
  }

  .chipIcon {
    margin-right: 4px;
    color: ${Theme.white};
  }
  .label {
    margin-bottom: 4px;
  }
`

const TagChip = props => {
  const { data, handleFilterByTag } = props;
  const iconMapping = {
    academics: <FaAppleAlt key="1"/>,
    administration:	<FaLandmark key="2" />,
    arts: <FaPalette key="2.5" />,
    athletics:	<FaBasketballBall key="3" />,
    cafeteria:	<FaUtensils key="4" />,
    cheerleading:	<FaCertificate key="5" />,
    church:	<FaChurch key="6" />,
    construction:	<FaTools key="7" />,
    donors:	<FaMoneyBillWave key="8" />,
    events:	<FaCalendarAlt  key="9" />,
    faculty:	<FaChalkboardTeacher key="10" />,
    housing:	<FaHome key="11" />,
    library: <FaBookReader key="21" />,
    Malibu:	<FaUmbrellaBeach key="12" />,
    newspapers:	<FaNewspaper key="13" />,
    politics:	<FaRepublican key="14" />,
    religion:	<FaBible key="15" />,
    "graduate schools":	<FaGraduationCap key="16" />,
    security:	<FaLock key="17" />,
    sewer:	<FaToilet key="18" />,
    "student government":	<FaVoteYea key="19" />,
    "student life":	<FaUserFriends key="20"/>,
  };
  
  return <ChipWrapper onClick={_ => handleFilterByTag(data)}>
    <span className="chipIcon">{iconMapping[data]}</span>
    <span className="label">{data}</span>
  </ChipWrapper>;
};

export default TagChip;
