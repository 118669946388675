const stories = [
  {
    id: "S72NEWS",
    events: ["720204A", "720330A", "720720C", "720728A", "721006A"],
    title: "The Graphic moves to Malibu",
    description: {
      text: "With the opening of the Malibu campus in September 1972, Pepperdine's award-winning student newspaper |The Graphic|, which had been operating on the LA campus since the school's first term, followed the liberal arts program to Malibu. Students on the LA campus started a new paper, called |Inner View|, which published its first issue in July of that year. The first Malibu issue of |The Graphic| came in October 1972, a month after the new campus opened.\nClick through the timeline below for details on Pepperdine's student newspapers in 1972.",
    sources: [],
  },
    seeAlso: {
      events: [],
      people: [],
    },
    photo: {
      path: "/assets/Graphic.png",
      caption: "The front page of The Graphic's first issue from the Malibu campus in October 1972.",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/7717/rec/80",
        title: "The Graphic",
        date: "10/6/72",
        publisher: "Pepperdine University Archives",
        page: "1",
      },
    },
    order: 7,
  },
  {
    id: "S72SEWR",
    events: ["720316A", "720504A", "720507A", "720622A", "720724A", "720906A"],
    title: "The great Malibu sewer saga",
    description: {
      text: "The opening of the Malibu campus was nearly delayed because administrators couldn't seem to decide what to do with all the sewage.\nAt first, they had plans to build a water treatment facility to be shared with a neighboring housing development. When that fell through, they tried to get permits for a system of septic tanks as a temporary solution. But they couldn't get the county to allow septic tanks, so for a time it looked like they would have to truck the sewage to an offsite facility. That plan proved so impractical that it didn't last long.\nFinally, they decided to rush construction of a pipeline to a water treatment facility through Malibu Canyon. When the campus opened, it had only just been connected to the new system.\nClick through the timeline below for the all the details.",
      sources: [],
    },
    seeAlso: {
      events: [],
      people: [],
    },
    photo: {
      path: "/assets/Sewer.png",
      caption: "Sewage pipes on the Malibu campus with nowhere to go after county officials rejected Pepperdine's plan for a temporary sewer system.",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/236/rec/72",
        title: "The Graphic",
        date: "5/4/72",
        publisher: "Pepperdine University Archives",
        page: "1",
      },
    },
    order: 4,
  },
  {
    id: "S72MHSG",
    events: ["720217C", "720316B", "720602A", "720608A", "720615A", "720731A", "720906A", "721110A", "721117C"],
    title: "Malibu housing",
    description: {
      text: "Malibu real estate is famously pricy, so one of the first challenges of the new campus was finding housing for everyone. Building dorms is one thing, but the faculty needed somewhere to live, too.\nClick through the timeline below for the details on housing in Malibu in 1972.",
      sources: [
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: ""
        }
      ],
    },
    seeAlso: {
      events: [],
      people: [],
    },
    photo: {
      path: "/assets/MalibuHousing.png",
      caption: "Student residences under construction on the Malibu campus in 1972, with Darnell House and Eaton House in the foreground.",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/2992/rec/4",
        title: "Student residences under construction, 1972",
        date: "1972",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    order: 9,
  },
  {
    id: "S72LHSG",
    events: ["720217C", "720518B", "720720A", ],
    title: "LA campus empties out",
    description: {
      text: "With the departure of the liberal arts program to Malibu in the fall of 1972, Pepperdine's LA campus transitioned to a less residential model. In the end, the departure of the residential program to Malibu would spell the end of the LA campus, but in 1972 it was still a happening place, even if the residential students were mostly gone.\nClick through the timeline below for the details.",
      sources: [
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: ""
        }
      ],
    },
    seeAlso: {
      events: [],
      people: [],
    },
    photo: {
      path: "/assets/LAHousing.png",
      caption: "Baxter Hall, a men's dormitory on the LA campus, shown soon after its construction in 1937.",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/62/rec/1",
        title: "Baxter Hall at Pepperdine College, circa 1937",
        date: "1937",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    order: 10,
  },
  {
    id: "S72WHIN",
    events: ["720929A", "721006C", "721013A"],
    title: "LA students' inferiority complex",
    description: {
      text: "As soon as the Malibu campus opened in September 1972, students on the LA campus couldn't help but worry that the grass was greener on the other side of the Santa Monica Bay. They complained that food was better in Malibu, that Malibu had taken too many library books from LA, and that their tuition dollars were supporting operations in Malibu (they weren't).\nOne of the main outlets for these concerns was the new student newspaper on the LA campus, |Inner View|. Click through the timeline below for all the details.",
      sources: [
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: ""
        }
      ],
    },
    seeAlso: {
      events: [],
      people: [],
      stories: ["S72LHSG"],
    },
    photo: {
      path: "/assets/LAComplaints.png",
      caption: "A collage of headlines from Inner View in the fall of 1972.",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p15730coll31/id/1369/rec/5",
        title: "Inner View",
        date: "9/29/72, 10/6/72, 10/13/72",
        publisher: "Pepperdine University Archives",
        page: "",
      },
    },
    order: 8,
  },
  {
    id: "S72DONO",
    events: ["721006B", "721006E", "721103A", "721117A", "721215B"],
    title: "Bizarre gifts roll in",
    description: {
      text: "A giant eagle sculpture. A rickety old lighthouse. Forged paintings. A mature tree. These were some of the unsolicited donations to Malibu when the new campus opened in September 1972. They didn't all pay off, but they sure were weird. Click through the timeline below for the details.",
      sources: [
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: ""
        }
      ],
    },
    seeAlso: {
      events: [],
      people: [],
    },
    photo: {
      path: "/assets/Lighthouse.png",
      caption: "The lighthouse was damaged during the move.",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/5315/rec/35",
        title: "iMprints, 1973",
        date: "8/3/73",
        publisher: "Pepperdine University Archives",
        page: "16",
      },
    },
    order: 2,
  },
  {
    id: "S72TREE",
    events: ["720616B", "720906C", "721103A", "721106A"],
    title: "Making Malibu beautiful again",
    description: {
      text: "The Malibu campus was a barren construction zone when it opened in September 1972. Leo Hirsh made sure it didn't stay that way for long. Landscaping served for more than just aesthetics, though. Erosion control was one major concern, leading to an effort to sow grass on the hillsides.\nClick through the timeline below for highlights from the landscaping project that created the most beautiful campus in the country.",
      sources: [
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: ""
        }
      ],
    },
    seeAlso: {
      events: [],
      people: [],
    },
    photo: {
      path: "/assets/Sower.png",
      caption: "A student sows grass on a barren campus hillside.",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/527/rec/83",
        title: "The Graphic",
        date: "11/3/72",
        publisher: "Pepperdine University Archives",
        page: "1",
      },
    },
    order: 6,
  },
  {
    id: "S72TOWR",
    events: ["720401A", "720401B", "720515A", "720517A", "720526A", "720526B", "720531A"],
    title: "How the Theme Tower came to be",
    description: {
      text: "Drama surrounded the construction of Pepperdine's iconic Phillips Theme Tower. From architectural disagreements to county permits to neighbors' objections, the project had significant hurdles to overcome.\nClick through the timeline below for the details on Pepperdine's signature work of architecture, from its earliest conception in the mind of architect William Pereira to the legal challenges by neighbors in Malibu that almost put a stop to its construction.",
      sources: [
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: ""
        }
      ],
    },
    seeAlso: {
      events: [],
      people: [],
    },
    photo: {
      path: "/assets/PereiraTower.png",
      caption: "A painting of the Theme Tower by William L. Pereira Associates, the architecture firm behind the design.",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/19417/rec/20",
        title: "Sketch of the Phillips Theme Tower",
        date: "1972",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    order: 1,
  },
  {
    id: "S72OPEN",
    events: ["720905A", "720906A", "720906B", "720906C", "720906D", "720906E", "720906F", "720906G", "720910A", "720912A"],
    title: "Pepperdine Malibu: the first week",
    description: {
      text: "The dream of Pepperdine Malibu became a reality in early September 1972 with the arrival of students to campus, but it didn't come off without a few last obstacles.\nClick through the timeline below for all the details on the first week at Pepperdine Malibu.",
      sources: [
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: ""
        }
      ],
    },
    seeAlso: {
      events: [],
      people: [],
    },
    photo: {
      path: "/assets/MoveIn.png",
      caption: "A student moving into the new dorms on the Malibu campus, Sept. 6, 1972, before wheeled suitcases were common.",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/1059/rec/7",
        title: "Student moving into new Malibu dormitories, 1972",
        date: "Sept. 1972",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    order: 5,
  },
  {
    id: "S72SECU",
    events: ["720406A", "720501A", "720512A", "720826A", "721002A"],
    title: "LA fights campus crime",
    description: {
      text: "South LA wasn't the safest place for a college campus in the early 1970s. Arson and burglary were among the crimes that caused Pepperdine to tighten security on the LA campus repeatedly in 1972. In the meantime, most of the residential students were planning to move to the new Malibu campus.\nClick through the timeline below for the details.",
      sources: [
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: ""
        }
      ],
    },
    seeAlso: {
      events: [],
      people: [],
    },
    photo: {
      path: "/assets/Security.png",
      caption: "Student security officer Randy Hodges works at the new guard booth on the LA campus in April 1972.",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/953/rec/71",
        title: "The Graphic",
        date: "4/6/1972",
        publisher: "Pepperdine University Archives",
        page: "4",
      },
    },
    order: 3,
  },
];

/*
{
    id: "",
    events: [],
    title: "",
    description: {
      text: "",
      sources: [
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: ""
        }
      ],
    },
    seeAlso: {
      events: [],
      people: [],
    },
    photo: {
      path: "/assets/.png",
      caption: "",
      source: {
        url: "",
        title: "",
        date: "",
        publisher: "",
        page: "",
      },
    },
  },
*/

export default stories;