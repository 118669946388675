import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';

const PhotoWrapper = styled.div`
  margin: 0.75rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  img {
    max-width: 95vw;
    max-height: 95vw;
    width: 30rem;
    height: 30rem;
    padding: 0.25rem;
    object-fit: cover;
    object-position:50% 50%;
  }

  .caption {
    width: 30rem;
    max-width: 90vw;
    color: ${Theme.lightGray};
    font-size: 0.8rem;
  }

  .photosource {
    color: ${Theme.mediumGray};
  }
`

const CaptionedPhoto = props => {
  const { path, caption, source } = props.photo;
  const { url, title, date, page, publisher } = source;

  return (
    <PhotoWrapper>
      <a href={url ?? ""} target="_blank" rel="noreferrer">
        <img src={path} alt={caption} />
      </a>
      <p className="caption">
          {caption}
          <span className="photosource">
          &nbsp;<a href={url ?? ""} target="_blank" rel="noreferrer">{title}</a>,&nbsp;
          {date}, p. {page} ({publisher})
        </span>
      </p>
    </PhotoWrapper>
  )
}

export default CaptionedPhoto;
