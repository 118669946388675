const getColor = (i, brightness) => {
  let ir = parseInt(i[0]);
  let ig = parseInt(i[1]);
  let ib = parseInt(i[2]);
  let one = Math.floor(brightness/(ir+ig+ib));
  if (one * ir > 255) {
    ir = 255 / one;
  }
  if (one * ig > 255) {
    ig = 255 / one;
  }
  if (one * ib > 255) {
    ib = 255 / one;
  }
  
  let r = (one * ir).toString(16);
  let g = (one * ig).toString(16);
  let b = (one * ib).toString(16);
  return "#" + r + g + b;
}
const Theme = {
  // baseline colors
  primary: "#00205c",
  secondary: "#c25700",
  accent: "#0078b0",
  black: "#231f20",
  textBlack: "#231f20",
  white: "#FFF",
  backgroundWhite: "#EEEEEE",
  mediumGray: "#787878",
  lightGray: "#444",

  // generated colors
  red: getColor("411", 400),
  darkRed: getColor("411", 250),
  orange: getColor("641", 400),
  darkOrange: getColor("641", 250),
  green: getColor("141", 400),
  darkGreen: getColor("141", 250),
  cyan: getColor("144", 400),
  darkCyan: getColor("144", 250),
  blue: getColor("114", 400),
  darkBlue: getColor("114", 250),
  pink: getColor("414", 400),
  darkPink: getColor("414", 250),


  pBlue: "#00205c",
  pOrange: "#c25700",
  pLightBlue: "#98caec",
  pLightOrange: "#ff9f19",
  
  getColor: getColor,

  // fonts
  fontSize: '1rem',
  smallFont: '0.75rem',
  headerFont: '1.5rem',

  borderRadius: '0.75rem',
  boxShadow: `1.5px 1.5px 5px #787878`,

  space: '1rem',
  showPictures: true, // move the `assets` dir in/out of `public` as necessary
  underConstruction: false,
  baseURL: process.env.PUBLIC_URL,
  prodURL: "https://www.quinquagenary1972.com"
}

export default Theme;