import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Theme from '../style/theme';
import events from '../data/events';
import Description from '../components/Description';
import SeeAlso from '../components/SeeAlso';
import Breadcrumbs from '../components/Breadcrumbs';
import HoverIcon from '../components/HoverIcon';
import CaptionedPhoto from '../components/CaptionedPhoto';
import { FaMapMarkerAlt, FaRegCalendarAlt } from 'react-icons/fa';
import TagChip from '../components/TagChip';
import NotFound from './NotFound';

const EventPageWrapper = styled.main`
  display: flex;
  flex-flow: column;
  align-items: center;
  background: ${Theme.backgroundWhite};

  h2 {
    color: ${Theme.pBlue};
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem;
  }

  .date {
    color: ${Theme.mediumGray};
    font-size: 1rem;
    font-style: italic;
    margin: 0.5rem;
  }

  .details {
    color: ${Theme.black};
    font-size: 1rem;
    margin: 0.5rem;
    height: 24px;
  }

  .icon {
    color: ${Theme.secondary};
    font-size: 1rem;
    margin-right: 2px;
  }

  .para {
    margin: 0.5rem;
    color: ${Theme.black};
    font-size: 1rem;
  }

  .sources {
    margin: 0.5rem;
    span { 
      font-weight: bold;
    }
  }

  .sourceLink {
    color: ${Theme.pBlue};
    text-decoration: none;
    
    span {
      color: ${Theme.black};
    }
  }

  .flexRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.5rem;
    width: 100%;
    max-width: 30rem;
  }

  .tags {
    margin-top: 4px;
    margin-right: 4px;
    font-weight: bold;
  }

  .tag {
    height: 2rem;
    padding: 4px;
    font-size: ${Theme.fontSize};
    background-color: ${Theme.pBlue};
    color: ${Theme.white};
    border-radius: ${Theme.borderRadius};
    margin-right: 4px;
    cursor: pointer;
  }

  .heading {
    width: 100%;
    max-width: 30rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

const EventPage = props => {
  const { breadcrumbs } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  const event = events.find(e => e.id === id);

  const handleFilterByTag = tag => navigate(`/timeline/?tag=${tag}`);

  const formatDateString = str => {
    const days = {
      Sun: "Sunday,",
      Mon: "Monday,",
      Tue: "Tuesday,",
      Wed: "Wednesday,",
      Thu: "Thursday,",
      Fri: "Friday,",
      Sat: "Saturday,"
    };
    const months = {
      Jan: "January",
      Feb: "February",
      Mar: "March",
      Apr: "April",
      May: "May",
      Jun: "June",
      Jul: "July",
      Aug: "August",
      Sep: "September",
      Oct: "October",
      Nov: "November",
      Dec: "December",
    }

    if (str[8] === '0') {
      str = str.slice(0, 8) + str.slice(9);
    }
    const withMonth = str.slice(0,4) + months[str.slice(4,7)] + str.slice(7)
    const withDays = days[withMonth.slice(0,3)] + withMonth.slice(3);
    return withDays.slice(0, withDays.length - 5) + ',' + withDays.slice(withDays.length - 5);
  }
  
  let title, date, location, description, photo, tags, seeAlso;
  
  if (event) {
    ({
      title,
      date,
      location,
      description,
      photo,
      tags,
      seeAlso
    } = event);

    breadcrumbs.push(title);
  }

  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0,0);
  }, [event]);

  let plainTextDescription;
  let truncatedDescription;
  if (!description) {
    return <NotFound redirectPath="timeline" targetPage="to timeline" />;
  } else {
    plainTextDescription = description.text.replace(/\|/g, "");
    truncatedDescription = plainTextDescription.length < 420 ? plainTextDescription : plainTextDescription.substring(0,419);
    }

  return (
    <EventPageWrapper>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={truncatedDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={truncatedDescription} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={truncatedDescription} />
        {(Theme.showPictures && photo)
          ? <meta name="twitter:image" content={"https://www.quinquagenary1972.com" + photo.path + "?2"} />
          : <meta name="twitter:image" content={"https://www.quinquagenary1972.com/50512.png"} />}
        {(Theme.showPictures && photo)
          ? <meta name="twitter:card" content="summary_large_image" />
          : <meta name="twitter:card" content="summary" />}
        {Theme.showPictures && photo && <meta name="twitter:image:alt" content={photo.caption} />}
        {Theme.showPictures && photo && <meta name="og:image" content={"https://www.quinquagenary1972.com" + photo.path} />}
      </Helmet>
      <Breadcrumbs pages={breadcrumbs} />
      <div className="heading">
        <h2>{title}</h2>
        <p className="details">
          <HoverIcon
            icon={<FaRegCalendarAlt />}
            color={Theme.secondary}
            hoverText="date"
          /> {formatDateString(date.toDateString())}
        </p>
        {location &&
          <p className="details">
            <HoverIcon
              icon={<FaMapMarkerAlt />}
              color={Theme.secondary}
              hoverText="location"
            /> {location}
          </p>}
      </div>
      {photo != null && Theme.showPictures && <CaptionedPhoto photo={photo} />}
      <Description description={description} />
      {tags.length > 0 &&
        <div className="flexRow">
          <p className="tags">Tags:</p>
          {tags.map(t => (
            <TagChip data={t} handleFilterByTag={handleFilterByTag} key={t} />
            ))}
        </div>}
      <SeeAlso data={seeAlso} />
    </EventPageWrapper>
  )
}

export default EventPage
