export const updateMetaTag = (tag, value, forTwitter = true) => {
  document.querySelector(`meta[${forTwitter ? "name" : "property"}="${tag}"]`)?.setAttribute("content", value);
}

export const resetMetaTags = () => {
  // Twitter tags
  updateMetaTag("twitter:card", "summary");
  updateMetaTag("twitter:title", "Quinquagenary: 50 years of Pepperdine Malibu");
  updateMetaTag("twitter:description", "A celebration of the fiftieth anniversary of Pepperdine's Malibu campus.");

  // OpenGraph tags
  updateMetaTag("og:title", "Quinquagenary: 50 years of Pepperdine Malibu", false);
  updateMetaTag("og:description", "A celebration of the fiftieth anniversary of Pepperdine's Malibu campus.", false);
  updateMetaTag("og:type", "website", false);

  // Photo tags (Twitter and OG)
  updateMetaTag("twitter:image", "%PUBLIC_URL%/50512.png");
  updateMetaTag("og:image", "%PUBLIC_URL%/50512.png", false);
}

export const monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];