import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../style/theme';
import {ReactComponent as EventIcon} from '../icons/event.svg';

const EventWrapper = styled.a`
  background: #cccccc;
  max-width: 25rem;
  height: 1.5rem;
  cursor: pointer;
  margin: 0.125rem;

  @media only screen and (min-width: 700px) {
    &:hover {
      background-color: ${Theme.pLightBlue};
    }
  }

  &:active {
    box-shadow: inset ${Theme.boxShadow};
  }
  
  .bar {
    background: ${Theme.pOrange};
    width: 4px;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .title {
    max-width: 13rem;
    font-size: 0.925rem;
    color: ${Theme.black};
    font-family: monospace;
    font-size: 0.85rem;
    padding-top: 3px;
  }

  .storyDate {
    padding: 0 0.5rem 0 0.25rem;
    font-size: 0.925rem;
    color: ${Theme.black};
    font-family: monospace;
    font-size: 0.85rem;
    padding-top: 3px;
  }

  .separator {
    color: ${Theme.pBlue};
    padding-left: 4px;
    margin-bottom: 6px;
  }

  .eventIcon {
    height: 1rem;
    width: 0.5rem;
    margin: 4px 6px 0 6px;
  }

  .floatLeft {
    float: left;
  }
`

const CompactEvent = props => {
  const { title, date, id } = props.event;
  const navigate = useNavigate();

  const titleToRender = title.length > 25 ? title.substring(0,22) + '…' : title;

  const goToEvent = e => {
    e.preventDefault();
    navigate(`/events/${id}`);
  }

  return (
    <EventWrapper href={"/events/" + id} onClick={goToEvent}>
      <span className="bar floatLeft">&nbsp;</span>
      <EventIcon className="eventIcon floatLeft" />
      <span className="title floatLeft">{titleToRender}</span>
      <span className="separator floatLeft">|</span>
      <span className="storyDate floatLeft">
        {date.getMonth() + 1}/{date.getDate()}/{date.getYear()}
      </span>
    </EventWrapper>
  )
}

export default CompactEvent;