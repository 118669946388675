import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';

const StoryWrapper = styled.div`
  background: ${Theme.white};
  box-shadow: ${Theme.boxShadow};
  border-radius: 0.5rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 358px;
  height: 450px;
  margin: 0.5rem;
  cursor: pointer;

  @media only screen and (min-width: 700px) {
    &:hover {
      box-shadow: 3px 3px 5px #585858;
    }
  }

  &:active {
    box-shadow: inset -1.5px -1.5px 3px #787878;
  }

  img {
    width: 350px;
    height: 350px;
    padding: 0.25rem;
    object-fit: fill;
    border-radius: 0.5rem;
  }

  h3 {
    color: ${Theme.primary};

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    color: ${Theme.mediumGray};
    padding: 0.25rem;
    max-width: 334px;
    font-size: 0.925rem;
    display: -webkit-box;
    overflow: hidden;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`

const StoryCard = props => {
  const { id, title, description, photo } = props.story;

  const re_super = /(<\d*>)/gi
  const re_ital = /(\|[a-zA-Z\s&-]*\|)/gi
  const formatSuperscript = p => 
    p.split(re_super).map((part, i) => re_super.test(part)
          ? (<sup key={i}>{part.slice(1, part.length - 1)}</sup>)
          : formatItalics(part));

  const formatItalics = p => 
    p.split(re_ital).map((part, i) => re_ital.test(part)
        ? (<em key={i}>{part.slice(1, part.length - 1)}</em>)
        : part);

  const descriptionToRender = description.text
    .split('\n')
    .map(p => (
      <p key={p} className='para'>
        {formatSuperscript(p)}
      </p>))

  return (
    <a href={"/stories/" + id} style={{textDecoration: "none"}}>
      <StoryWrapper>
        {Theme.showPictures
          ? <img src={photo.path} alt={photo.caption} />
          : <img src={"50512.png"} alt=""/>}
        <h3>{title}</h3>
        {descriptionToRender[0]}
      </StoryWrapper>
    </a>
  )
}

export default StoryCard
