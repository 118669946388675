import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../style/theme';

const AvatarWrapper = styled.div`
  /* height: 3rem; */
  display: flex;
  flex-flow: row nowrap;
  
  .initials {
    display: block;
    margin: 0.25rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
    background-color: ${props => props.gender === "M" ? "#00205c" : "#c25700"};
    color: ${Theme.white};
    cursor: pointer;
    text-decoration: none;
  }
  
  position: relative;
  display: inline-block;
  text-decoration: none;
  
  .bigInitials {
    border-radius: 50%;
    width: 3.25rem;
    height: 3.25rem;
    text-align: center;
    line-height: 3.25rem;
    background-color: ${props => props.gender === "M" ? "#00205c" : "#c25700"};
    color: ${Theme.white};
    margin: 0.5rem;
    cursor: pointer;
    text-decoration: none;
  }
  
  .avatar {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    object-fit:cover;
    object-position:50% 50%;
    margin: 0.25rem;
    cursor: pointer;
  }
  
  .hoverCard {
    visibility: hidden;
    position: absolute;
    top: -10px;
    left: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 16rem;
    height: 3.75rem;
    z-index: 1;
    background: #98caec;
    margin: 0.25rem;
    border-radius: ${Theme.borderRadius};
    box-shadow: ${Theme.boxShadow};
    text-decoration: none;
  }
  
  @media only screen and (min-width: 700px) {
    &:hover .hoverCard {
      visibility: visible;
    }
  }
  
  .vitals {
    font-size: 1rem;
    color: ${Theme.black};
    display: flex;
    flex-flow: column nowrap;
    line-height: 1rem;
    max-height: 3rem;
    overflow: clip;
    text-overflow: ellipsis;
    text-decoration: none;
  }

  .bigger {
    border-radius: 50%;
    height: 3.25rem;
    width: 3.25rem;
    margin: 0.5rem;
    cursor: pointer;
    text-decoration: none;
  }

  .name {
    color: ${Theme.pBlue};
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }

  .position {
    color: ${Theme.mediumGray};
    text-decoration: none;
  }
`

const Avatar = (props, context) => {
  const { initials, gender, photo, name, position } = props.person;
  const navigate = useNavigate();

  let pfp;
  let bigger;
  if (!photo || !Theme.showPictures) {
    pfp = (<a className="initials" href={"/people/" + name}>{initials}</a>);
    bigger = (<a className="bigInitials" href={"/people/" + name}>{initials}</a>)
  } else {
    pfp = (
      <a href={"/people/" + name}>
        <img src={photo.path} alt={name} className="avatar" />
      </a>)
    bigger = (
      <a href={"/people/" + name}>
        <img src={photo.path} alt={name} className="bigger" />
      </a>)
  }

  const goToProfile = () => navigate(`/people/${name}`);

  return <AvatarWrapper gender={gender} onClick={goToProfile}>
    {pfp}
    <div className="hoverCard">
      {bigger}
      <div className="vitals">
        <a className="name" href={"/people/" + name}>{name}</a>
        <p className="position">{position}</p>
      </div>
    </div>
  </AvatarWrapper>
}

export default Avatar;