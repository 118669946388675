import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';

const SourcesWrapper = styled.div`
  margin: 0.5rem;

  h4 {
    font-weight: bold;
  }
  ol {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  li {
    max-width: 26rem;
  }

  a {
    color: ${Theme.accent};
  }
`;

const Sources = props => {
  const { sources } = props;
  const navigate = path => window.location.href = path;

  const sourcesToRender = sources.map(source => {
    if (source.title === "Baird") {
      source.url = "/about#BairdSource";
      return source;
    } if (source.title === "Banowsky") {
      source.url = "/about#BanowskySource";
      return source;
    } return source;
  })

  if (sources.filter(s => s.title).length) {
    return (
      <SourcesWrapper>
        <h4>Sources</h4>
        <ol>
          {sourcesToRender.filter(s => s.title).map((s, i) => (
            <li key={i}>
                {s.url
                  ? (<React.Fragment>
                      <a
                        href={s.url}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => navigate(s.url)}>
                        {s.title}
                      </a>, {s.date}, p. {s.page} ({s.publisher})
                    </React.Fragment>)
                  : <React.Fragment>
                      {s.title}, {s.date}, p. {s.page} {s.publisher && `(${s.publisher})`}
                    </React.Fragment>}
            </li>
          ))}
        </ol>
      </SourcesWrapper>
    )
  } else {
    return <></>
  }
}

export default Sources;
