import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';
import ResultCard from './ResultCard';
import stories from '../data/stories';
import people from '../data/people';
import events from '../data/events';

const Wrapper = styled.div`
  a:last-child > div {
    border-bottom: 1px solid black;
  }
`;

const ResultsList = props => {
  const { query } = props;

  //#region filter and rank results
  const filterResults = () => {
    const q = query?.toLowerCase() ?? "";
    const results = [];

    stories.filter(s => {
      return s.description.text.toLowerCase().includes(q)
      || s.title.toLowerCase().includes(q)
      || s.photo?.caption?.toLowerCase()?.includes(q);
    })
      .map(s => new Result(s.title, s.id, s.description.text, "stories"))
      .forEach(r => results.push(r));
    people.filter(p => {
      return p.description.text.toLowerCase().includes(q)
      || p.name.toLowerCase().includes(q)
      || p.position.toLowerCase().includes(q)
      || p.seeAlso.people.map(p => p.toLowerCase()).some(p => p.includes(q));
    })
      .map(p => new Result(p.name, p.name, p.description.text, "people"))
      .forEach(r => results.push(r));
    events.filter(e => {
      return e.description.text.toLowerCase().includes(q)
      || e.title.toLowerCase().includes(q)
      || e.photo?.caption?.toLowerCase()?.includes(q)
      || e.location.toLowerCase().includes(q)
      || e.tags.map(t => t.toLowerCase()).includes(q)
      || e.seeAlso.people.map(p => p.toLowerCase()).some(p => p.includes(q));
    })
      .map(e => new Result(e.title, e.id, e.description.text, "events"))
      .forEach(r => results.push(r));

    return results;
  }

  const rankResults = results => {
    const q = query?.toLowerCase() ?? "";
    const resultsMap = {};

    const add = (key, inc) => {
      resultsMap[key] = (resultsMap[key] ?? 0) + inc;
    }

    results.forEach(r => {
      switch (r.type) {
        case "stories":
          if (r.name.toLowerCase().includes(q)) {
            add(r.name, 15);
          }
          if (r.description.toLowerCase().includes(q)) {
            let regex = new RegExp(q, 'gi');
            let matches = (r.description.match(regex) || []);
            add(r.name, matches.length * 2);
          }
          if (resultsMap[r.name] === undefined) {
            // if this story hasn't had points added, assign 0
            // this is for matches in the photo's `caption` field
            resultsMap[r.name] = 0;
          }
          break;
        case "people":
          if (r.name.toLowerCase().includes(q)) {
            // query appears in this person's name
            add(r.name, 11);
          }
          if (r.description.toLowerCase().includes(q)) {
            // query appears in this person's bio
            let regex = new RegExp(q, 'gi');
            let matches = (r.description.match(regex) || []);
            add(r.name, matches.length);
          }
          if (resultsMap[r.name] === undefined) {
            // if this person hasn't had points added, assign 0
            // this is for matches in the `position` field
            resultsMap[r.name] = 0;
          }
          let person = people.find(p => p.name === r.name);
          if (person != null) {
            if (person.seeAlso.people.map(p => p.toLowerCase()).some(p => p.includes(q))) {
              // query appears in this person's See Also people
              add(r.name, 1);
            }
          }
          break;
        case "events":
          if (r.name.toLowerCase().includes(q)) {
            // query appears in this event's title
            add(r.name, 10);
          }
          if (r.description.toLowerCase().includes(q)) {
            // query appears in this event's description
            // count number of appearances
            let regex = new RegExp(q, 'gi');
            let matches = (r.description.match(regex) || []);
            add(r.name, matches.length);
          }
          let event = events.find(e => e.id === r.path);
          if (event != null) {
            if (event.tags.map(t => t.toLowerCase()).includes(q)) {
              // query appears in this event's tags
              add(r.name, 3);
            } if (event.seeAlso.people.map(p => p.toLowerCase()).some(p => p.includes(q))) {
              // query appears in this event's See Also people
              add(r.name, 2);
            }
          }
          if (resultsMap[r.name] === undefined) {
            // if this event hasn't had any points added, assign 0
            // this is for matches in the photo's `caption` field
            resultsMap[r.name] = 0;
          }
          break;
        default:
          break;
      }
    });

    // console.log(Object.entries(resultsMap).sort((a,b) => b[1] - a[1]));

    // sort results (desc) by their score in resultsMap
    return Object.entries(resultsMap)
      .sort((a,b) => b[1] - a[1])
      .map(entry => entry[0]).map(e => results.find(r => r.name === e));
  }
  //#endregion

  if (!query) {
    return <Wrapper>
      <p>
        No results.
      </p>
    </Wrapper>
  }

  const rankedResults = rankResults(filterResults());

  return (
    <Wrapper>
      {rankedResults.length
        ? rankedResults.map(r => (<ResultCard data={r} key={r.name} />))
        : <p>
            No results.
          </p>}
    </Wrapper>
  )
}

export class Result {
  constructor(name, path, description, type) {
    this.name = name;
    this.path = path;
    this.description = description;
    this.type = type;
  }
}

export default ResultsList;