import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';

const BreadcrumbWrapper = styled.div`
  width: 100%;
  margin-left: 1rem;
  color: ${Theme.accent};
  cursor: pointer;
  font-size: ${Theme.fontSize};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  @media only screen and (min-width: 700px) {
    span:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-weight: normal;
    color: ${Theme.lightGray};
  }
`

const Breadcrumbs = props => {
  const { pages } = props;

  const allPages = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'Timeline',
      url: '/timeline',
    },
    {
      name: 'People',
      url: '/people',
    },
    {
      name: 'Stories',
      url: '/stories',
    },
    {
      name: 'About',
      url: '/about',
    },
    {
      name: 'Search results',
      url: '/search',
    }
  ];
  const pagesToRender = pages
  .map(p => allPages.find(a => a.name.toLowerCase() === p.toLowerCase()))
  .filter(p => p != null);

  if (pagesToRender.length < pages.length) {
    // add back person/event/story-specific breadcrumbs that aren't in allPages
    let missingCrumb = pages[pages.length - 1];
    pagesToRender.push({name: missingCrumb, url: ''});
  }

  return (
    <BreadcrumbWrapper>
      {pagesToRender.map((p, i) => (
          <React.Fragment key={i}>
            &nbsp;
            <a href={p.url}>
              {i + 1 === pagesToRender.length ? <strong>{p.name}</strong> : p.name} 
            </a>
            &nbsp;{i < pagesToRender.length - 1 ? '/' : ''}
          </React.Fragment>
          )
        )
      }
    </BreadcrumbWrapper>
  )
}

export default Breadcrumbs;
