import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';
import {ReactComponent as StoryIcon} from '../icons/story.svg';
import {ReactComponent as EventIcon} from '../icons/event.svg';
import { FaUser } from 'react-icons/fa';

const ResultCardWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid black;
  border-left: none;
  border-bottom: none;
  
  .cardText {
    background-color: ${Theme.white};
    cursor: pointer;
    color: ${Theme.black};
    text-decoration: none;
    width: 20rem;
    padding: 0.5rem;
    border-radius: 0 5px 5px 0;
    h4 {
      color: ${Theme.primary};
    }
  }

  .colorBar {
    width: 6px;
    margin: 0;
    padding: 0;
    border-radius: 5px 0 0 5px;
  }

  .cardTypeIcon {
    margin-right: 6px;
  }
`;

const ResultCard = (props) => {
  const { name, path, description, type } = props.data;

  //#region Format description
  const re_super = /(<\d*>)/gi
  const re_ital = /(\|[a-zA-Z\s&-]*\|)/gi
  const formatSuperscript = p => 
    p.split(re_super).map((part, i) => re_super.test(part)
          ? (<sup key={i}>{part.slice(1, part.length - 1)}</sup>)
          : formatItalics(part));

  const formatItalics = p => 
    p.split(re_ital).map((part, i) => re_ital.test(part)
        ? (<em key={i}>{part.slice(1, part.length - 1)}</em>)
        : part);

  const truncatedDescription = description.length < 120
  ? description
  : description.substring(0,116) + '...';
        
  const richTextDescription = truncatedDescription
    .split('\n')
    .map(p => (
      <p key={p} className='para'>
        {formatSuperscript(p)}
      </p>));
  //#endregion
  
  const getIcon = () => {
    switch (type) {
      case "stories":
        return <StoryIcon className="cardTypeIcon" />;
      case "people":
        return <FaUser className="cardTypeIcon" color={Theme.primary} />;
      case "events":
        return <EventIcon className="cardTypeIcon" />;
      default:
        return <span />;
    }
  }

  return (
    <a href={Theme.baseURL + '/' + type + '/' + path}>
      <ResultCardWrapper>
        <div
          className="colorBar"
          style={{background: type === 'stories'
            ? Theme.accent
            : type === "events"
              ? Theme.secondary
              : Theme.primary}}
        />
        <div className="cardText">
          <h4>
            {getIcon()}
            {name}
          </h4>
          {richTextDescription}
        </div>
      </ResultCardWrapper>
    </a>
  )
}

export default ResultCard;