import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './style/GlobalStyle';
import { Routes, Route } from 'react-router-dom';
import Theme from './style/theme';
import PageSkeleton from './components/PageSkeleton';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import TimelinePage from './pages/TimelinePage';
import People from './pages/People';
import Stories from './pages/Stories';
import Profile from './pages/Profile';
import EventPage from './pages/EventPage';
import StoryPage from './pages/StoryPage';
import About from './pages/About';
import SearchResults from './pages/SearchResults';
import UnderConstruction from './pages/UnderConstruction';
import NotFound from './pages/NotFound';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <PageSkeleton>
        {Theme.underConstruction ? <UnderConstruction /> :
        (<>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              path="/search"
              element={<SearchResults breadcrumbs={['home', 'search results']} />}
            />
            <Route exact
              path="/timeline"
              element={<TimelinePage breadcrumbs={['home', 'timeline']} />}
            />
            <Route
              path="/timeline/?tag=:tag"
              element={<TimelinePage breadcrumbs={['home', 'timeline']} />}
            />
            <Route exact
              path="/people"
              element={<People breadcrumbs={['home', 'people']} />}
            />
            <Route exact
              path="/stories"
              element={<Stories breadcrumbs={['home', 'stories']} />}
            />
            <Route
              path="/people/:id"
              element={<Profile breadcrumbs={['home', 'people']} />}
            />
            <Route
              path="/events/:id"
              element={<EventPage breadcrumbs={['home', 'timeline']} />}
            />
            <Route
              path="/stories/:id"
              element={<StoryPage breadcrumbs={['home', 'stories']} />}
            />
            <Route
              path="/about"
              element={<About breadcrumbs={['home', 'about']} />}
            />
            <Route
              path="/events/*"
              element={<NotFound />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </>)}
      </PageSkeleton>
    </ThemeProvider>
  );
}

export default App;
