import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Theme from '../style/theme';
import Breadcrumbs from '../components/Breadcrumbs';
import StoryCard from '../components/StoryCard';
import stories from '../data/stories';

const PageWrapper = styled.main`
  background: ${Theme.backgroundWhite};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .flexBox {
    display: flex;
    flex-flow: row wrap;
    width: 90%;
    height: 100%;
    justify-content: center;
  }
`

const Stories = props => {
  const { breadcrumbs } = props;

  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  
  return (
    <PageWrapper>
      <Helmet>
        <title>Pepperdine stories from 1972</title>
        <meta name="description" content="From the origins of the theme tower to the Malibu sewer saga, discover what was happening at Pepperdine in the year the Malibu campus opened." />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Pepperdine Stories from 1972"
        />
        <meta
          name="twitter:description"
          content="From the origins of the theme tower to the Malibu sewer saga, discover what was happening at Pepperdine in the year the Malibu campus opened."
        />
        <meta name="twitter:image" content={"https://www.quinquagenary1972.com/50512.png"} />
        <meta name="twitter:image:alt" content="" />
        <meta
          property="og:title"
          content="Pepperdine Stories from 1972"
        />
        <meta
          property="og:description"
          content="From the origins of the theme tower to the Malibu sewer saga, discover what was happening at Pepperdine in the year the Malibu campus opened."
        />
        <meta property="og:image" content={"https://www.quinquagenary1972.com/50512.png"} />
      </Helmet>
      <Breadcrumbs pages={breadcrumbs} />
      <h1>Stories from 1972</h1>
      <div className="flexBox">
        {stories
          .sort((a, b) => a.order - b.order)
          .map(s => (<StoryCard story={s} key={s.id} />))}
      </div>
    </PageWrapper>
  )
}

export default Stories;
