import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Theme from '../style/theme';
import Breadcrumbs from '../components/Breadcrumbs';

const PageWrapper = styled.main`
  background: ${Theme.backgroundWhite};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;

  .para {
    font-size: 1rem;
    text-align: justify;
    max-width: 30rem;
    margin: 0.5rem;
    color: ${Theme.black};
  }

  h3 {
    text-align: left;
    max-width: 30rem;
    width: 100%;
    margin: 1rem 0 0 1rem;
  }

  ul {
    margin: 0 1rem 0 0;
    max-width: 30rem;
    text-align: justify;
  }
`

const About = ({ breadcrumbs }) => {
  return (
    <PageWrapper>
      <Helmet>
        <title>About Quinquagenary: 50 years of Pepperdine Malibu</title>
        <meta name="description" content="Quinquagenary is a retrospective on Pepperdine in 1972, celebrating the 50th anniversary (or quinquagenary) of the opening of the Malibu campus." />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="About Quinquagenary: 50 years of Pepperdine Malibu"
        />
        <meta
          name="twitter:description"
          content="Quinquagenary is a retrospective on Pepperdine in 1972, celebrating the 50th anniversary (or quinquagenary) of the opening of the Malibu campus."
        />
        <meta name="twitter:image" content={"https://www.quinquagenary1972.com/50512.png"} />
        <meta name="twitter:image:alt" content="" />
        <meta
          property="og:title"
          content="About Quinquagenary: 50 years of Pepperdine Malibu"
        />
        <meta
          property="og:description"
          content="Quinquagenary is a retrospective on Pepperdine in 1972, celebrating the 50th anniversary (or quinquagenary) of the opening of the Malibu campus."
        />
        <meta property="og:image" content={"https://www.quinquagenary1972.com/50512.png"} />
      </Helmet>
      <Breadcrumbs pages={breadcrumbs} />
      <h1>About Quinquagenary</h1>
      <p className="para">
        <em>Quinquagenary</em> is a retrospective on Pepperdine in 1972, celebrating the 50th anniversary (or quinquagenary) of the opening of the Malibu campus.
      </p>
      <h3>Sources</h3>
      <p className="para">
        The project was not produced by Pepperdine University, but it owes a debt of gratitude to sources published by the university. A project of this nature would be much more difficult without access to&nbsp;
        <a
          href="https://pepperdine.contentdm.oclc.org/digital/"
          target="_blank"
          rel="noreferrer"
        >
          Pepperdine Libraries Digital Collections
        </a>, and especially its&nbsp;
        <a
          href="https://infoguides.pepperdine.edu/universityarchives"
          target="_blank"
          rel="noreferrer"
        >
          University Archives
        </a> collection, which maintains a full-text, searchable database of digitized issues of&nbsp;
        <a
          href="https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05"
          target="_blank"
          rel="noreferrer"
        >
          <em>The Graphic</em>
        </a>,&nbsp;
        <a
          href="https://pepperdine.contentdm.oclc.org/digital/collection/p15730coll31" 
          target="_blank"
          rel="noreferrer"
        >
          <em>Inner View</em>
        </a>, and&nbsp;
        <a
          href="https://pepperdinecontentdm.oclc.org/digital/collection/"
          target="_blank"
          rel="noreferrer"
        >
          <em>The Malibu Times</em>
        </a>,&nbsp;
        as well as the&nbsp;
        <a
          href="https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15"
          target="_blank"
          rel="noreferrer"
        >
          Photograph Collection
        </a> and the&nbsp;
        <a
          href="https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01"
          target="_blank"
          rel="noreferrer"
        >
          Yearbook Collection
        </a>,
        both of which are indispensable resources for uncovering Pepperdine's history.
      </p>
      <p className="para">
        Also valuable were books from Pepperdine University Press, especially these two from the 2010s:
      </p>
      <ul>
        <li  id="BairdSource">
          <em>Quest for Distinction: Pepperdine University in the 20th Century</em>, an academic history by former Seaver College dean David Baird (2016), credited throughout this project as "Baird". This is one of the most reliable sources of information on the history of Pepperdine. Baird is a thorough and balanced historian, and his well-researched book should be the first place scholars and enthusiasts go for carefully sourced information about Pepperdine's history, though it isn't without occasional lacunae and <Link to="/events/720409A">errata</Link>.
        </li>
        <li id="BanowskySource">
          <em>The Malibu Miracle</em>, a memoir by former president William S. Banowsky (2010), credited throughout this project as "Banowsky". This book gives an up-close-and-personal look at what it took to make Pepperdine's Malibu campus a reality, as told by the man at the center of it all. Banowsky attempts to provide footnoted sources that accord with his memory, but he sometimes makes mistakes. In <Link to="/events/721107B">places</Link>, his timeline is not as reliable as Baird's, but his account gives texture to topics where Baird is a bit thin. Banowsky's book should be read and appreciated for what it is: a memoir, not an academic history like Baird's.
        </li>
      </ul>
      <h3>Contact us</h3>
      <p className="para">
        To get in touch, send an email to <a href="mailto:quinquagenary1972@gmail.com?subject=Quinquagenary">quinquagenary1972@gmail.com</a> or reach out to <a href="https://twitter.com/Mal_Hibou" target="_blank" rel="noreferrer">@Mal_Hibou</a> on Twitter.
      </p>
    </PageWrapper>
  )
}

export default About;
