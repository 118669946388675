const people = [
  {
    name: "M. Norvel Young",
    initials: "NY",
    photo: {
      path: "/assets/MNorvelYoung2.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/9884/rec/20",
        title: "Ronald Reagan and others with Malibu campus rendering at Pepperdine's Birth of a College dinner, 1970",
        date: "2/9/1970",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["Helen Young", "Steven Lemley", "Sara Young Jackson", "William S. Banowsky", "Leonard K. Firestone", "Morris Pendleton", "Walter Knott", "Blanche E. Seaver"],
      events: ["711221A"],
    },
    role: "admin",
    position: "Chancellor",
    description: {
      text: "In 1972, Matt Norvel Young was the chancellor of Pepperdine, a position created just for him that allowed him to continue to raise funds without the same level of administrative responsibilities. He also served as chair of the board of trustees. Young had transitioned into both roles at the beginning of 1971, when his protégé William Banowsky was named president.<1>\nYoung had previously served as Pepperdine's third president from his appointment in 1957. Before coming to Pepperdine, Young had been the minister of the Broadway Church of Christ in Lubbock, Texas,<2> which was the largest church of Christ in the country (and the world).<3> Young's association with Pepperdine began much earlier: he served as a professor of history in the school's first years. He met his wife Helen at George Pepperdine College, where she was a student.\nYoung played a significant role in realizing the dream of Pepperdine Malibu. He cultivated relationships with donors in Southern California long before Banowsky took office, including Firestone, Pendleton, Knott, and Seaver.\nYoung was the patriarch of one of Pepperdine's great dynasties. His wife Helen founded the Associated Women for Pepperdine (today called the Pepperdine Legacy Partners). In 1972, his son-in-law Steven Lemley was hired at Pepperdine; Lemley would serve as provost in the 1990s. Young's daughter Sara later followed her father into the chancellery, the position she holds today. Young's grandson, whose birth was announced in the pages of |The Malibu Times| in 1972, now serves on the religion faculty at Seaver College.",
      sources: [
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "327",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "85",
        },
        {
          url: "",
          title: "Time",
          date: "8/5/1957",
          publisher: "",
          page: "58"
        }
      ],
    },
    gender: "M",
  },
  {
    name: "William S. Banowsky",
    initials: "WB",
    photo: {
      path: "/assets/WilliamSBanowsky.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/1094/rec/58",
        title: "William S. Banowsky, circa 1970",
        date: "1970",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["M. Norvel Young", "Howard A. White", "Blanche E. Seaver", "Leonard K. Firestone"],
      events: ["720101A", "720125A", "720218A", "720217B", "720507A", "720526A", "720925A"],
    },
    role: "admin",
    position: "President",
    description: {
      text: "In 1972, William Slater Banowsky was president of Pepperdine University. His term began January 1, 1971, when his mentor Pepperdine president Norvel Young assumed the role of chancellor.<1>\nBanowsky was a graduate of sister-school David Lipscomb College in Nashville. He worked as Young's assistant at Pepperdine briefly in the 1960s while studying for his doctorate at USC.<2> He then became the minister of the Broadway Church of Christ in Lubbock, Texas, one of the largest congregations in the churches of Christ at the time. He returned to Pepperdine as executive vice president in 1968.<1>\nAs president, Banowsky spent most of his time making the Malibu campus a reality: raising funds, approving architectural plans, and overseeing construction. He spent over 80% of his time on these external pursuits, delegating most internal operations to a team of administrators including executive vice president Howard White.<1>\nBanowsky was highly skilled as a fundraiser, bringing in many tens of millions of dollars in donations to support the construction of the Malibu campus. He formed a special relationship with donor Blanche Seaver, who would give over $160 million to Pepperdine over the course of many years.<3>\nPart of Banowsky's success as a fundraiser was due to his ability to connect with donors over their shared conservative politics. Banowsky was involved in Republican politics in California, chairing Nixon's re-election campaign in LA county in 1972,<4> and serving in other roles throughout the years. Many of the donors Banowsky targeted were similarly involved, including Seaver, Margaret Brock, and Leonard Firestone. In 1972, he had a weekly television program on KNBC called \"The Sunday Show.\"\nIn 1978, Banowsky left Pepperdine to serve as president of the University of Oklahoma. He was succeeded in Malibu by Howard White.",
      sources: [
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "327",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "12-13",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "283",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/2781/rec/65",
          title: "The Graphic",
          date: "2/17/72",
          publisher: "Pepperdine University Archives",
          page: "1, 4",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Howard A. White",
    initials: "HW",
    photo: {
      path: "/assets/HowardAWhite.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/5365/rec/1",
        title: "Imprints 1973",
        date: "1973",
        publisher: "Pepperdine University Archives Yearbook Collection",
        page: "66",
      },
    },
    seeAlso: {
      people: ["M. Norvel Young", "William S. Banowsky"],
      events: ["711231A", "720127B", "721006D"],
    },
    role: "admin",
    position: "Executive vice president",
    description: {
      text: "In 1972, Howard Ashley White was Pepperdine's executive vice president. He was largely responsible for the day-to-day operation of the university while chancellor Norvel Young and president William Banowsky raised money for the new Malibu campus.<1> White was among the earliest Pepperdiners to move to Malibu, moving to a home near Trancas in December 1971.<2>\nWhite had been a student (and later a professor) at sister-school David Lipscomb College in Nashville. He followed Norvel Young to Pepperdine after Young became president in 1957.<3>\nWhite went on to succeed Banowsky in the presidency, serving in that role from 1978 to 1985.<4> A professor of history before his administrative turn, White was the last president of Pepperdine to hold a PhD. Both the HAWC student center and the university's teaching award are named for White. Dorm 6 (White house) is named in honor of his wife, Maxcine Feltman White, who died in 1973.",
      sources: [
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "264",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/11477/rec/693",
          title: "The Malibu Times",
          date: "12/31/71",
          publisher: "Pepperdine University Archives",
          page: "1",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "4",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "409-11",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Jerry Hudson",
    initials: "JH",
    photo: {
      path: "/assets/JerryHudson.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/5365/rec/1",
        title: "Imprints 1973",
        date: "1973",
        publisher: "Pepperdine University Archives Yearbook Collection",
        page: "66",
      },
    },
    seeAlso: {
      people: ["Howard A. White", "William S. Banowsky", "Randy Lowry"],
      events: ["720316A", "720504A", "720602A", "720616A", "720720B", "720906E", "721006A"],
    },
    role: "admin",
    position: "Provost, Malibu",
    description: {
      text: "In 1972, Jerry Hudson was provost of Pepperdine's Malibu campus, though the position encompassed more than just academic responsibilities. Hudson's role in realizing the dream of Pepperdine Malibu can hardly be overstated.\n|The Graphic|, in its first issue from Malibu, praised Hudson effusively: \"Here is the man whose hair has literally turned gray between the time Malibu was first proposed and the campus' opening. [...] Pepperdine University owes Dr. Hudson its thanks, not just for the tremendous job he has done in getting the new campus open, but for his ongoing job as provost.\"<1>\nHudson had been a student of Howard White at sister-school David Lipscomb College in Nashville, coming to Pepperdine to teach history in 1962.<2> President William Banowsky named Hudson provost and dean of the Malibu campus in 1971, and Hudson oversaw the construction of the campus as well as the development of the new curriculum.<2>\nHudson had aspirations to be Banowsky's successor in the presidency, but after a conflict about the faculty, Hudson took a job as president of Hamline University in Minnesota, beginning in 1975. Shortly thereafter, Pepperdine's board offered Hudson the presidency back in Malibu, but he declined.<2> Hudson went on to serve as president of Willamette University in Oregon.<3>\nIn his memoir, William Banowsky remembers Hudson as having played \"an indispensable Malibu miracle role.\"<3>",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/7720/rec/80",
          title: "The Graphic",
          date: "10/6/72",
          publisher: "Pepperdine University Archives",
          page: "4",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "305-6",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "234",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Jennings Davis",
    initials: "JD",
    photo: {
      path: "/assets/JenningsDavis.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p15730coll13/id/1663/rec/3",
        title: "George Pepperdine College Student Handbook 1968-1969",
        date: "1968",
        publisher: "Pepperdine University Archives Student Life Collection",
        page: "4",
      },
    },
    seeAlso: {
      people: ["M. Norvel Young"],
      events: ["720127B", "720217C"],
    },
    role: "admin",
    position: "Dean of students, LA",
    description: {
      text: "In 1972, Jennings Davis served as the dean of students at Pepperdine's LA campus, a position he had held since 1963.<1> Davis had previously been a professor at sister-school David Lipscomb College (now Lipscomb University) in Nashville. He followed Norvel Young to Pepperdine after Young became president in 1957.<2>\nDavis was a critic of the university's more objectionable racial attitudes, opposing a tradition in which (often Black) members of the homecoming court were auctioned as \"slaves\" to the highest bidder.<3> When much of Pepperdine moved to Malibu in 1972, Davis chose to stay behind and serve on the Los Angeles campus, which he did for years.<4>",
      sources: [
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "186",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "4",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "158-9",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "161",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Bob Thomas",
    initials: "BT",
    photo: {
      path: "/assets/BobThomas.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/1148/rec/5",
        title: "Howard A. White and Bob Thomas at all-university dinner, 1976",
        date: "Oct. 1976",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: [],
      events: ["720203B", "720525A", "721006A"],
    },
    role: "admin",
    position: "Dean of student life, Malibu",
    description: {
      text: "In the fall of 1972, Robert Thomas was dean of student life for the new Malibu campus. He had previously served as dean of admissions at the LA campus.<1>\nThomas would later become president of a university in Montana.<2> Thomas's daughter, Cathy Thomas-Grant, is now professor of theater at Seaver College.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/13629/rec/725",
          title: "The Malibu Times",
          date: "8/11/72",
          publisher: "Pepperdine University Archives",
          page: "10",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "264",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Steven Lemley",
    initials: "SL",
    photo: {
      path: "/assets/StevenLemley.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/5354/rec/1",
        title: "Imprints 1973",
        date: "1973",
        publisher: "Pepperdine University Archives Yearbook Collection",
        page: "55",
      },
    },
    seeAlso: {
      people: ["M. Norvel Young", "Helen Young"],
      events: ["711221A", "720811A"],
    },
    role: "admin",
    position: "Associate dean of student life",
    description: {
      text: "In the summer of 1972, Steven Lemley was hired to be associate dean of student life at the new Malibu campus.<1> Fresh off his PhD from Ohio State, Lemley was married to Emily Young, the eldest daughter of chancellor Norvel Young and Helen Young. The birth of their first son (Norvel and Helen's first grandchild) was announced in the pages of |The Malibu Times| in January 1972.<2>\nAfter a stint as president of sister-school Lubbock Christian College (now Lubbock Christian University), Lemley would return to Pepperdine as provost in 1993, a position he held until 2000, when he rejoined the faculty.<3> The child born in 1971—David Lemley—now serves in Seaver College's religion department.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/13629/rec/725",
          title: "The Malibu Times",
          date: "8/11/72",
          publisher: "Pepperdine University Archives",
          page: "10",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/13384/rec/695",
          title: "The Malibu Times",
          date: "1/14/72",
          publisher: "Pepperdine University Archives",
          page: "3",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "521",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Ronald Phillips",
    initials: "RP",
    photo: {
      path: "/assets/RonPhillips.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/2566/rec/10",
        title: "Mr. Richard Seaver, Unknown, Mrs. Jamie Phillips, Dean Ronald Phillips (Color)",
        date: "date unknown",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["M. Norvel Young"],
      events: ["720323A", "720915A"],
    },
    role: "admin",
    position: "Dean, School of Law",
    description: {
      text: "In the fall of 1972, Ronald Phillips began his third year as dean of the Pepperdine School of Law. A graduate of sister-school Abilene Christian College, Phillips received his legal training at the University of Texas. In 1970, Norvel Young hired him to be the first permanent dean of the law school, taking over from interim dean Vincent Dalsimer, who continued on for a year after Pepperdine bought the Orange University College of Law in 1969.<1>\nPhillips served as dean for 28 years, taking the school from an unaccredited night school in Orange County to a nationally recognized school in Malibu. Improbably, Phillips remains on the Pepperdine payroll through the time of writing (2022), making him perhaps the only Pepperdine employee from 1972 who is still in the university's employ. Today, he serves as senior vice chancellor and dean emeritus.\nThe Phillipses have been one of Pepperdine's great extended families over the past fifty years, with the affiliation stretching across three generations. Today, Ronald's son Phil serves as COO and senior vice president for administration. Other Pepperdine Phillipses include Shannon (dean of admissions at the law school) and Sean Michael (associate vice president of human resources and business services).",
      sources: [
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "248",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Helen Young",
    initials: "HY",
    photo: {
      path: "/assets/HelenYoung.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/2094/rec/20",
        title: "Nancy Myers and Helen Young, mid 1970s",
        date: "9/20/1975",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["M. Norvel Young", "Steven Lemley", "Sara Young Jackson"],
      events: ["711221A", "720125A"],
    },
    role: "other",
    position: "Former first lady",
    description: {
      text: "In  1972, Helen Young was the wife of chancellor Norvel Young. She remained involved with the Associated Women for Pepperdine, a fundraising organization she founded in 1958, which today is called Pepperdine Legacy Partners. The Youngs became grandparents at the end of 1971.<1>\nIn his history of the churches of Christ, historian Richard Hughes calls Young \"perhaps the most visible and influential woman among Churches of Christ in the second half of the twentieth century.\"<2>\nThe Youngs are one of the great Pepperdine dynasties, and Helen was the family matriarch. Helen's son-in-law Steven Lemley was provost of Pepperdine in the 1990s, and her daughter Sara Young Jackson is currently the chancellor.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/13384/rec/695",
          title: "The Malibu Times",
          date: "1/14/72",
          publisher: "Pepperdine University Archives",
          page: "3",
        },
        {
          url: "",
          title: "Hughes",
          date: "1996",
          publisher: "William B. Eerdmans",
          page: "383",
        },
      ],
    },
    gender: "F",
  },
  {
    name: "Sara Young Jackson",
    initials: "SJ",
    photo: {
      path: "/assets/SaraJackson.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/13360/rec/6",
        title: "Moot Court Banquet with Ed Meese",
        date: "1/31/81",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["M. Norvel Young", "Helen Young"],
      events: ["720317A"],
    },
    role: "other",
    position: "Former first family",
    description: {
      text: "In 1972, Sara Young was the 20-year-old daughter of Pepperdine chancellor (and former president) M. Norvel Young. She attended sister-school David Lipscomb College in Nashville, Tennessee, but in March of that year she was home visiting her family at the Adamson House in Malibu. On a horseback ride, she was thrown from her \"frisky pony,\" and rolled down an embankment, bruising her back.<1>\nToday, Sara Young Jackson is herself the chancellor of Pepperdine, after holding a variety of roles at the university, where she founded both the Volunteer Center and the Boone Center for the Family.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/13737/rec/704",
          title: "The Malibu Times",
          date: "3/17/72",
          publisher: "Pepperdine University Archives",
          page: "4",
        },
      ],
    },
    gender: "F",
  },
  {
    name: "Randy Lowry",
    initials: "RL",
    photo: {
      path: "/assets/RandyLowry.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/7721/rec/80",
        title: "The Graphic",
        date: "10/6/72",
        publisher: "Pepperdine University Archives",
        page: "1",
      },
    },
    seeAlso: {
      people: ["Bob Thomas", "Jerry Hudson"],
      events: ["720203A", "720518A", "721006A"],
    },
    role: "student",
    position: "Chairman, student board",
    description: {
      text: "In the spring of 1972, Randolph (Randy) Lowry was an undergraduate student at Pepperdine's Los Angeles campus. He was a member of the debate team.<1> In the fall, Lowry relocated to Malibu, where he served as the inaugural chair of the Associated Student Board, the new student government for the Malibu campus (a position for which he was handpicked by dean of student life Bob Thomas).<2> Lowry was a protégé of Malibu provost Jerry Hudson, who would take Lowry with him to his appointments as president of both Hamline University in St. Paul and Willamette University in Salem, Oregon.<3>\nLowry would go on to be a professor at the Pepperdine School of Law, where in 1986 he founded the alternate dispute resolution program that is now the Straus Institute for Dispute Resolution.<3> In 1999, he was among five finalists in the search for Pepperdine's seventh president (ultimately, Andrew K. Benton was selected).<4> Lowry would later be named the 18th president of sister-school Lipscomb University in Nashville.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/4871/rec/63",
          title: "The Graphic",
          date: "2/3/72",
          publisher: "Pepperdine University Archives",
          page: "1",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/7721/rec/80",
          title: "The Graphic",
          date: "10/6/72",
          publisher: "Pepperdine University Archives",
          page: "1",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "260",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "572",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "William \"Bird\" Averitt",
    initials: "WA",
    photo: {
      path: "/assets/BirdAveritt.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/358/rec/8",
        title: "Student William \"Bird\" Averitt, 1974",
        date: "1974",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "1",
      },
    },
    seeAlso: {
      people: ["Gary Colson"],
      events: ["720108A", "720316C", "720108A", "720121B", "720122A", "720130A", "720214A", "720219A", "721016A", "721125A"],
    },
    role: "student",
    position: "Student athlete (basketball)",
    description: {
      text: "In the spring of 1972, William Averitt was a sophomore and the star of Pepperdine's basketball team. He broke the school's 15-year-old single-season scoring record in February. His Waves did not have a strong season. In the fall, his scoring was even more prodigious. In the 1972-1973 season, he ended up breaking his own scoring record. To this day, he has eight of the ten highest single-game point totals on the Waves' all-time list.\nAfter his junior year, Averitt joined the ABA, first with the San Antonio Spurs, then with the Kentucky Colonels, where he won an ABA championship in 1975. When the ABA was folded into the NBA in 1976, Averitt joined the Buffalo Braves (the franchise that would later become the Los Angeles Clippers) and then the New Jersey Nets.",
      sources: [
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: "",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Karen Bua",
    initials: "KB",
    photo: {
      // path: "/assets/KarenBua.png",
      // caption: "",
      // source: {
      //   url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/2578/rec/2",
      //   title: "Imprints 1975",
      //   date: "1975",
      //   publisher: "Pepperdine University Archives Yearbook Collection",
      //   page: "47",
      // },
      path: "/assets/Bua2.jpg",
      caption: "",
      source: {
        url: "",
        title: "Coach & Athlete",
        date: "March 1972",
        publisher: "Allen Dees",
        page: "30",
      },
    },
    seeAlso: {
      people: [],
      events: ["720428A", "721110B", "730216A"],
    },
    role: "student",
    position: "Student cheerleader",
    description: {
      text: "In the spring of 1972, Karen Bua was a cheerleader at Pepperdine's Los Angeles campus. A sophomore, she was featured in the March issue of |Coach & Athlete| magazine. |The Malibu Times| raved, \"WOOOOOOOW, Karen is a REAL page brightener!!!\".<1>\nBua moved to the new Malibu campus when it opened in the fall, where she became head cheerleader.<2> Bua and her squad came under fire on the front page of |The Graphic| in February 1973, with the president of the student body charging, \"There is a lot of ill feeling toward the cheerleaders,\" and a Graphic editorial calling them \"generally uninspired.\"<3>\nIn 1978, Bua married then-coach of the Los Angeles Lakers, Jerry West.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/13254/rec/710",
          title: "The Malibu Times",
          date: "4/28/72",
          publisher: "Pepperdine University Archives",
          page: "4",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/7447/rec/2",
          title: "The Graphic",
          date: "11/10/72",
          publisher: "Pepperdine University Archives",
          page: "2",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/483/rec/92",
          title: "The Graphic",
          date: "2/16/73",
          publisher: "Pepperdine University Archives",
          page: "1, 2, 4",
        },
      ],
    },
    gender: "F",
  },
  {
    name: "Debbie Bronder",
    initials: "DB",
    photo: null,
    seeAlso: {
      people: ["Karen Bua"],
      events: ["720630B"],
    },
    role: "student",
    position: "Student",
    description: {
      text: "Pepperdine senior Debbie Bronder was named winner of the 1972-1973 Miss Carson beauty pageant. The blonde would serve as an official representative of the City of Carson at various dedications and ground-breaking ceremonies, as well as at the LA County fair.<1>\nTwo years earlier, Carson had been a yell leader (a kind of cheerleader) for the Waves.<2>",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/13317/rec/719",
          title: "The Malibu Times",
          date: "6/30/72",
          publisher: "Pepperdine University Archives",
          page: "9",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/4255/rec/1",
          title: "The Graphic",
          date: "10/22/70",
          publisher: "Pepperdine University Archives",
          page: "2",
        },
      ],
    },
    gender: "F",
  },
  {
    name: "Gualberto Escudero",
    initials: "GE",
    photo: {
      path: "/assets/GualbertoEscudero.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/10956/rec/24",
        title: "Impressions 1981",
        date: "1981",
        publisher: "Pepperdine University Archives Yearbook Collection",
        page: "134",
      },
    },
    seeAlso: {
      people: [],
      events: ["721215A"],
    },
    role: "student",
    position: "Student athlete (tennis)",
    description: {
      text: "Gualberto Escudero was among the first graduates from the Malibu campus.<1> After spending a single term in Malibu, Escudero and three classmates were the only Malibu representatives at Pepperdine's fall graduation ceremony in December 1972.<2>\nIn 1978, Escudero was named coach of Pepperdine's women's tennis team, a position he held for some 30 years.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/13726/rec/745",
          title: "The Malibu Times",
          date: "12/29/72",
          publisher: "Pepperdine University Archives",
          page: "6",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/6703/rec/87",
          title: "The Graphic",
          date: "12/8/72",
          publisher: "Pepperdine University Archives",
          page: "1",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Stephen F. Parmelee",
    initials: "SP",
    photo: null,
    seeAlso: {
      people: [],
      events: ["720113D"],
    },
    role: "student",
    position: "Student",
    description: {
      text: "In 1972, Stephen Parmelee was a sophomore at Pepperdine's Los Angeles campus. He was included in the Dean's list for the fall 1971 term with a GPA of 3.93.<1> Today, Parmelee is associate professor English and film at Seaver College.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/9880/rec/61",
          title: "The Graphic",
          date: "1/13/72",
          publisher: "Pepperdine University Archives",
          page: "2",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Raleigh Neal Runnels",
    initials: "RR",
    photo: null,
    seeAlso: {
      people: ["Charles Runnels"],
      events: ["720701A"],
    },
    role: "student",
    position: "Prospective student",
    description: {
      text: "Raleigh Neal Runnels was the son of Pepperdine vice chancellor Charles Runnels. For two years, he planned to enter the freshman class at the opening of Pepperdine's Malibu campus in September 1972.<1> Sadly, after a two-year fight with melanoma,<2> Raleigh died at the age of 17 just two months before the campus would open (and two weeks after his high school graduation).<3>\nRaleigh's funeral was conducted by Pepperdine chancellor Norvel Young at his Inglewood Church of Christ.<3> Three days after the funeral, Blanche Seaver, who had attended services at the Inglewood church with Raleigh, committed $650,000 to fund Pepperdine's swimming pool, on the condition that it be named the Raleigh Runnels Memorial Pool.<1> Further donations of $300,000 more came from Morris Pendleton and others.\nIn the 1984 Summer Olympics, the water polo competitions were held in the pool named for Runnels.",
      sources: [
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "256",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "303",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/13967/rec/722",
          title: "The Malibu Times",
          date: "7/21/72",
          publisher: "Pepperdine University Archives",
          page: "12",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Blanche E. Seaver",
    initials: "BS",
    photo: {
      path: "/assets/BlancheSeaver.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/9489/rec/5",
        title: "Blanche Seaver touring Brock House construction, 1973",
        date: "1/13/73",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["William S. Banowsky"],
      events: ["720217B", "720218A", "720701A"],
    },
    role: "donor",
    position: "Donor",
    description: {
      text: "Blanche Ebert Seaver was the donor who made the biggest impact on Pepperdine's Malibu campus. The liberal arts college that opened there in September 1972 would later be named in honor of Blanche's husband Frank R. Seaver, whose fortune funded the construction of much of the campus.<1>\nBlanche was the daughter of Norwegian immigrants to Chicago.<2> She showed musical talent from a young age and would compose and arrange songs for the likes of John McCormack and the Philadelphia Orchestra under the direction of Leopold Stokowski.<3> Blanche and Frank were married in 1916.<3>\nFrank and Blanche were generous donors to educational causes in Southern California, including $7.5 million to Frank's alma mater of Pomona College, where the Seaver Science Center was named in their honor,<4> and more than a million dollars to USC.<5> Frank died in 1964,<5> leaving half of his significant fortune to Blanche, and donating the rest.<6>\nFrank Seaver's wealth came mostly from his Hydril company, which manufactured oilfield equipment, specializing in blowout preventers,<7> which were used on oil rigs around the world.<8> Seaver got his start in the oil business under Edward Doheny, one of the wealthiest men in the nation.<7> It was Doheny's drilling company that Frank bought in 1928 and re-organized into the Hydril company.<8>\n"
      +
      "One of the recipients of Hydril stock following Frank's death was Pepperdine College.<6> That donation launched a long association between Blanche and Pepperdine, which Blanche called \"that little Christian college my beloved Frank cared about enough to remember in his will.\"<6> Blanche's relationship with president William Banowsky resulted in her donating over $160 million to Pepperdine over several years,<9> causing Banowsky to call her \"perhaps the most important Malibu miracle personality.\"<2>\nIn Banowsky's memoir, he recalls first taking Blanche to the hillside in Malibu that would come to bear her name, where she declared, \"I'm tired of scattering my money here and there. I want to focus on this place. I want to focus my contributions on something that will last for the ages.\"<10>\nSeaver was not easy to court: she expected her companions to wear American flag pins on their lapels. She drank no alcohol, and her escorts were expected to abstain in her presence. She was involved in Republican politics, though she was an early Republican detractor of Richard Nixon (then of Gerald Ford when he pardoned Nixon).<11>\nSeaver was a devout Catholic for much of her life, though at the age of 86, she was baptized (by Banowsky) at the Inglewood Church of Christ.<12> She died at the age of 102 in 1994. Banowsky officiated at her service.<13>",
      sources: [
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "312ff.",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "118",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "118-122",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "122",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "124-25",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "126",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "129",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "122",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "283",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "129",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "285",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "286",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "130",
        },
      ],
    },
    gender: "F",
  },
  // {
  //   name: "Richard Scaife",
  //   initials: "RS",
  //   photo: {
  //     path: "/assets/RichardScaife.png",
  //     caption: "",
  //     source: {
  //       url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/3579/rec/60",
  //       title: "Scaife Terrace Dedication",
  //       date: "12/28/1978",
  //       publisher: "Pepperdine University Archives Photograph Collection",
  //       page: "",
  //     },
  //   },
  //   seeAlso: {
  //     people: [],
  //     events: [],
  //   },
  //   role: "donor",
  //   position: "Donor",
  //   description: {
  //     text: "",
  //     sources: [
  //       {
  //         url: "",
  //         title: "",
  //         date: "",
  //         publisher: "",
  //         page: "",
  //       },
  //     ],
  //   },
  //   gender: "M",
  // },
  {
    name: "Morris Pendleton",
    initials: "MP",
    photo: {
      path: "/assets/MorrisPendleton.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/1948/rec/5",
        title: "Morris Pendleton (Color)",
        date: "c. 1971",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["Walter Knott", "George Pepperdine"],
      events: ["720701A"],
    },
    role: "donor",
    position: "Donor",
    description: {
      text: "Morris Pendleton was one of the donors whose generosity made possible Pepperdine's Malibu campus. His long affiliation with the university included service on the university board and later on the board of regents. From 1966-1967, he served with Walter Knott on the site selection committee that evaluated properties for Pepperdine's suburban expansion.<1>\nPendleton funded the gymnasium on the LA campus as well as the Pendleton Learning Center on the Malibu campus.<2> He made his money with the Plomb Tool Company (later the Pendleton Tool Company),<2> which sold wrenches and other such tools.\nPendleton had been a personal friend of George Pepperdine.<3>",
      sources: [
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "268",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "288",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "326",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Leonard K. Firestone",
    initials: "LF",
    photo: {
      path: "/assets/LeonardFirestone.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/18316/rec/3",
        title: "Leonard Firestone and Bob Bales",
        date: "1973",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["M. Norvel Young", "William S. Banowsky"],
      events: ["720302A"],
    },
    role: "donor",
    position: "Donor",
    description: {
      text: "In 1972, Leonard Firestone was the former president of Firestone Tire and Rubber Company.<1> Firestone was a friend of Nelson Rockefeller, and a supporter of Republican political causes. He served as US Ambassador to Belgium under Richard Nixon.<2> He was a member of the President's Board, a group of potential donors courted  by chancellor Norvel Young and president William Banowsky, and his name now graces the Firestone Fieldhouse.<3>\nIn 1970, Firestone was approached by Young and Banowsky, who offered to name the Los Angeles campus Firestone College in exchange for $4 million over several years. Firestone refused, claiming Pepperdine's LA campus was unlikely to survive long in its urban location. He did offer, however, to contribute to the new Malibu campus.<2>",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/8656/rec/68",
          title: "The Graphic",
          date: "3/2/72",
          publisher: "Pepperdine University Archives",
          page: "1",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "175-77",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "298",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Walter Knott",
    initials: "WK",
    photo: {
      path: "/assets/WalterKnott.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/2586/rec/1",
        title: "L to R: Dr. M. Norvel Young, Mr. Walter Knott, Mr. Edward Di Loreto",
        date: "date unknown",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["M. Norvel Young", "William S. Banowsky", "Leonard K. Firestone"],
      events: [],
    },
    role: "donor",
    position: "Donor",
    description: {
      text: "Walter Knott made his name as the owner of Knott's Berry Farm, a theme park in Orange County, California, and as the first commercial grower of boysenberries. He was targeted by Norvel Young and William Banowsky as one of a handful of potential donors capable of making a million-dollar gift to Pepperdine.<1>\nKnott was also a member of the committee that evaluated potential sites for Pepperdine's suburban expansion in the late 1960s,<2> and he served with Leonard Firestone on the President's Board, a group of potential donors courted by Norvel Young.<3>\nKnott's name now graces Knott House (dorm 12) on the Malibu campus.",
      sources: [
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "111",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "68",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "352",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Jerene Appleby Harnish",
    initials: "JH",
    photo: {
      path: "/assets/JereneHarnish.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/2466/rec/1",
        title: "Mrs. Jerene Harnish",
        date: "7/1/71",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: [],
      events: ["720409A"],
    },
    role: "donor",
    position: "Donor",
    description: {
      text: "Jerene Appleby Harnish gave $5 million dollars to Pepperdine, including a $1 million naming gift to fund the Appleby Center for American Studies, which was dedicated in 1972. Harnish was a newspaper publisher and proponent of conservative Republican politics.<1>\nIn later years, she would also fund the law library, which is named in her honor.",
      sources: [
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "289",
        },
      ],
    },
    gender: "F",
  },
  {
    name: "Fritz Huntsinger",
    initials: "FH",
    photo: {
      path: "/assets/FritzHuntsinger.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/19635/rec/1",
        title: "Fritz Huntsinger",
        date: "1973",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["George Pepperdine"],
      events: ["721006B", "721006A"],
    },
    role: "donor",
    position: "Donor",
    description: {
      text: "Fritz Huntsinger was one of the major donors behind Pepperdine's Malibu campus. The Huntsinger Academic Complex, which included Payson Library and Pendleton Learning Center, was largely paid for with a $2.6 million cash donation from its namesake. In addition to giving money, Huntsinger donated a giant wooden statue of an eagle that was placed in Payson Library.<1>\nAn immigrant from Germany, Huntsinger made his money selling oilfield tools through his company Vetco Offshore Industries (Vetco was short for Ventura Tool Company).<1> Huntsinger was a personal friend of George Pepperdine, serving on Pepperdine's university board and later board of regents for over two decades, beginning in 1958.<2>\nToday, Huntsinger is perhaps most recognizable as the namesake of Huntsinger Circle, which runs from the baseball field past the Rho lot to the law school.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/7720/rec/80",
          title: "The Graphic",
          date: "10/6/72",
          publisher: "Pepperdine University Archives",
          page: "4",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "288-9",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Clint Murchison, Jr.",
    initials: "CM",
    photo: {
      path: "/assets/ClintMurchison.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/9496/rec/4",
        title: "Clint Murchison and M. Norvel Young touring Brock House construction, 1973",
        date: "1/13/73",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: [],
      events: ["720403A"],
    },
    role: "donor",
    position: "Donor",
    description: {
      text: "In 1972, Clint Murchison, Jr., was the owner of the Dallas Cowboys. His donation to Pepperdine got his name on the Murchison Science Center, one of the major academic buildings on the Malibu campus. Following Murchison's death in 1987, the building was renamed for alumnus Leon Rockwell.<1>\nA member of the university board, he was named to the board of regents in 1976.<2>\nMurchison made his money in the oil business. He received an honorary doctorate from Pepperdine in 1964.<3>",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/19817/rec/95",
          title: "The Graphic",
          date: "1/12/95",
          publisher: "Pepperdine University Archives",
          page: "A2",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/347/rec/8",
          title: "The Graphic",
          date: "11/5/76",
          publisher: "Pepperdine University Archives",
          page: "3",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/2744/rec/79",
          title: "The Graphic",
          date: "4/10/1964",
          publisher: "Pepperdine University Archives",
          page: "2",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Leo Hirsh",
    initials: "LH",
    photo: {
      path: "/assets/LeoHirsh.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/10026/rec/11",
        title: "Tree Planting Ceremony",
        date: "9/6/1972",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: [],
      events: ["720616B", "720906C"],
    },
    role: "other",
    position: "Trees for Pepperdine Malibu",
    description: {
      text: "Leo Hirsh was the chairman of the Trees for Pepperdine Malibu Committee, a 1972 fundraising effort aiming to beautify the Malibu campus.<1>",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/12844/rec/717",
          title: "The Malibu Times",
          date: "6/16/72",
          publisher: "Pepperdine University Archives",
          page: "12",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Frank Pack",
    initials: "FP",
    photo: {
      path: "/assets/FrankPack.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/9583/rec/64",
        title: "The Graphic",
        date: "2/10/72",
        publisher: "Pepperdine University Archives",
        page: "6",
      },
    },
    seeAlso: {
      people: ["Tony Ash"],
      events: ["720127B", "720210A"],
    },
    role: "faculty",
    position: "Chair, religion dept. (LA)",
    description: {
      text: "In the spring of 1972, Pack was the chair of Pepperdine's religion department on the LA campus.<1> In the fall, he relocated to the Malibu campus, where he joined chair Tony Ash in the religion department.<2> For much of the 1970s, Pack also served as the dean of the graduate school, before it became the Graduate School of Education in 1978.<3>",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/9583/rec/64",
          title: "The Graphic",
          date: "2/10/72",
          publisher: "Pepperdine University Archives",
          page: "6",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "391",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "233",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Tony Ash",
    initials: "TA",
    photo: {
      path: "/assets/TonyAsh.PNG",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/5362/rec/1",
        title: "Imprints 1973",
        date: "1973",
        publisher: "Pepperdine University Archives Yearbook Collection",
        page: "63",
      },
    },
    seeAlso: {
      people: ["Jerry Hudson", "James Smythe"],
      events: ["070910A", "721016B"],
    },
    role: "faculty",
    position: "Chair, religion dept. (Malibu)",
    description: {
      text: "In the fall of 1972, Tony Ash was the chairman of the religion department on Pepperdine's new Malibu campus and the preacher at the young Church of Christ of Malibu. Ash was recruited from sister-school Abilene Christian College in Texas, where he had made a name for himself in the churches of Christ as a minister and scholar.<1> He was recruited to the Malibu campus when it opened in 1972.<2> \nAsh served on the committee that developed the new interdisciplinary curriculum for the Malibu campus with Jerry Hudson and James Smythe.<3>\nHe was the featured speaker at the 36th annual Founder's Day convocation on the LA campus.<4>",
      sources: [
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "391-2",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "312",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "266-68",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/3030/rec/82",
          title: "The Graphic",
          date: "10/20/72",
          publisher: "Pepperdine University Archives",
          page: "1-2",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "James Smythe",
    initials: "JS",
    photo: {
      path: "/assets/JamesSmythe.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/5358/rec/1",
        title: "Imprints 1973",
        date: "1973",
        publisher: "Pepperdine University Archives Yearbook Collection",
        page: "59",
      },
    },
    seeAlso: {
      people: ["Jerry Hudson", "Tony Ash", "George Pepperdine"],
      events: ["720127A", "721016B"],
    },
    role: "faculty",
    position: "Professor of English",
    description: {
      text: "In the spring of 1972, James Smythe was an English professor and faculty leader at Pepperdine's Los Angeles campus. He was among the faculty invited to relocate to Malibu when it opened that fall. In Malibu, Smythe served as chair of the humanities division.<1> Smythe was the chair of the Faculty Advisory Committee, a group advocating for faculty interests on the LA campus<2> (a role that he continued to hold in the new version of that committee in Malibu).<3>\nSmythe also served on a committee that designed the curriculum for the new campus. Among those serving with him on the committee were Jerry Hudson and Tony Ash.<4>\nSmythe spoke at the 36th annual Founder's Day chapel in Malibu, where he reminisced about his friend George Pepperdine.<1>\nSmythe served Pepperdine for 60 years, including as university archivist.<5>",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/3030/rec/82",
          title: "The Graphic",
          date: "10/20/72",
          publisher: "Pepperdine University Archives",
          page: "1-2",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/7194/rec/62",
          title: "The Graphic",
          date: "1/27/72",
          publisher: "Pepperdine University Archives",
          page: "1-2",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "371",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "266-68",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Archives",
          page: "259-60",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "George Pepperdine",
    initials: "GP",
    photo: {
      path: "/assets/GeorgePepperdine.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/51/rec/33",
        title: "George Pepperdine, 1956",
        date: "1956",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["Helen Pepperdine"],
      events: ["720731B", "721016B"],
    },
    role: "donor",
    position: "Founder",
    description: {
      text: "George Pepperdine College was founded in 1937, funded almost entirely by its namesake. Pepperdine made his fortune by founding the Western Auto Company, an auto parts retail company he later franchised. Pepperdine died in 1962. The tenth anniversary of his death passed without much fanfare in 1972, at which point his (second) wife Helen Pepperdine remained on the board of what was by that point Pepperdine University.",
      sources: [
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: "",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Helen Pepperdine",
    initials: "HP",
    photo: {
      path: "/assets/HelenPepperdine.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p15730coll2/id/251/rec/32",
        title: "Portrait of Helen Pepperdine at about age 73",
        date: "1976",
        publisher: "Pepperdine University Archives George Pepperdine Collection",
        page: "",
      },
    },
    seeAlso: {
      people: ["George Pepperdine"],
      events: ["720906C"],
    },
    role: "donor",
    position: "Trustee, founder's wife",
    description: {
      text: "In 1972, Helen Pepperdine served on the board of trustees of the university her late husband George had founded 35 years earlier. Helen had opposed the choice of the site in Malibu for Pepperdine's suburban expansion in 1968, preferring a property in Westlake Village where the Westlake Village Community Park is located today.<1>\nHelen Louise Davis met George Pepperdine in 1932, following the death of his first wife, Lena. Helen and George were married in 1934, three years before he founded the college that would bear his name.<2>",
      sources: [
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "274-77",
        },
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "10",
        },
      ],
    },
    gender: "F",
  },
  {
    name: "Seniel Ostrow",
    initials: "SO",
    photo: null,
    seeAlso: {
      people: [],
      events: ["721215B"],
    },
    role: "donor",
    position: "Donor",
    description: {
      text: "In 1972, Seniel Ostrow was the president and owner of the Sealy Mattress Company. He owned land adjacent to the Malibu campus, 160 acres of which he donated to Pepperdine in 1972.<1> Ostrow had given 50 acres in 1969, and he would give nine more in 1981.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/13237/rec/152",
          title: "The Malibu Times",
          date: "12/15/72",
          publisher: "Pepperdine University Archives",
          page: "1",
        },
        {
          url: "",
          title: "",
          date: "",
          publisher: "",
          page: "",
        },
      ],
    },
    gender: "M",
  },
  // {
  //   name: "Donald V. Miller",
  //   initials: "DM",
  //   photo: {
  //     path: "/assets/DonaldMiller.png",
  //     caption: "",
  //     source: {
  //       url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/9948/rec/10",
  //       title: "Ronald Reagan shaking hands with M. Norvel Young at Pepperdine's Birth of a College dinner, 1970",
  //       date: "2/9/1970",
  //       publisher: "Pepperdine University Archives Photograph Collection",
  //       page: "",
  //     },
  //   },
  //   seeAlso: {
  //     people: [],
  //     events: [],
  //   },
  //   role: "admin",
  //   position: "Chair, Board of Trustees",
  //   description: {
  //     text: "",
  //     sources: [
  //       {
  //         url: "",
  //         title: "",
  //         date: "",
  //         publisher: "",
  //         page: "",
  //       },
  //     ],
  //   },
  //   gender: "M",
  // },
  {
    name: "Charles Canfield",
    initials: "CC",
    photo: null,
    seeAlso: {
      people: ["Blanche E. Seaver"],
      events: ["720929B"],
    },
    role: "donor",
    position: "Donor",
    description: {
      text: "Charles Canfield was a business partner of the oil tycoon Edward Doheny, who at the height of his wealth was one of the richest men in America, and who was a close friend of Frank Seaver, the husband of Blanche Seaver.<1> Canfield died in 1913.\nIn 1972, the Canfield Foundation, named in his honor, announced a grant of one million dollars to be used for scholarships at Pepperdine University. The Foundation was established by Canfield before his death, and much of the money was earmarked for the education of young people.<2>",
      sources: [
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "117",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/12889/rec/732",
          title: "The Malibu Times",
          date: "9/29/72",
          publisher: "Pepperdine University Archives",
          page: "1",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Fred Solomon",
    initials: "FS",
    photo: null,
    seeAlso: {
      people: [],
      events: ["720615A"],
    },
    role: "donor",
    position: "Donor",
    description: {
      text: "Fred Solomon was an early landowner in Topanga Canyon. His widow donated two Topanga parcels of about 70 acres each to Pepperdine. The land was worth about $500,000 at the time. In 1972, Pepperdine considered building 140 condominiums to house Malibu faculty on one of the plots.<1> The condos were never built.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/2934/rec/77",
          title: "The Graphic",
          date: "6/15/72",
          publisher: "Pepperdine University Archives",
          page: "1",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Robert Newton",
    initials: "RN",
    photo: null,
    seeAlso: {
      people: [],
      events: ["720512A"],
    },
    role: "student",
    position: "Alumnus, arsonist",
    description: {
      text: "Pepperdine graduate Robert Newton was convicted of \"entering a public building with intent to commit a felony\" and second degree burglary but found innocent of arson. He was sentenced to three years in jail and a $500 fine.\nThe charges came after fires in two buildings on Pepperdine's LA campus in the fall of 1971. Witnesses said they saw Newton leaving the area after each fire. The fires caused a total of about $200 dollars in damage, and structural damage was only discovered in one building.<1>",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/5915/rec/74",
          title: "The Graphic",
          date: "5/18/72",
          publisher: "Pepperdine University Archives",
          page: "1",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Marv Dunphy",
    initials: "MD",
    photo: {
      path: "/assets/MarvDunphy.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/9455/rec/2",
        title: "Impressions 1978",
        date: "1978",
        publisher: "Pepperdine University Yearbook Collection",
        page: "134",
      },
    },
    seeAlso: {
      people: [],
      events: ["720622B", "721125B"],
    },
    role: "student",
    position: "Student athlete (volleyball)",
    description: {
      text: "In 1972, Marvin (Marv) Dunphy was recruited for the volleyball team from Pierce College, where he had been team captain.<1> Dunphy's first game as a Wave took place November 25, 1972,<2> a 2-2 tie against LA Valley College that had to end early so the basketball game scheduled after it could begin on time.<3>\nDunphy went on to coach volleyball at Pepperdine beginning in 1977 and lasting for several decades. He also had success as the head coach of the US national men's volleyball team, winning an Olympic gold medal at the 1988 summer Olympics in Seoul. Dunphy is a legend in the volleyball world, and he will be remembered as one of the greatest figures in the history of Pepperdine athletics.",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/6262/rec/3",
          title: "The Graphic",
          date: "6/22/72",
          publisher: "Pepperdine University Archives",
          page: "3",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/8608/rec/4",
          title: "The Graphic",
          date: "11/17/72",
          publisher: "Pepperdine University Archives",
          page: "6",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/7288/rec/86",
          title: "The Graphic",
          date: "12/1/72",
          publisher: "Pepperdine University Archives",
          page: "4",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Dennis Lowe",
    initials: "DL",
    photo: null,
    seeAlso: {
      people: [],
      events: [],
    },
    role: "student",
    position: "Student",
    description: {
      text: "In the fall of 1972, Dennis Lowe was a junior at Pepperdine's Malibu campus.<1>\nToday, he is a Professor at Pepperdine's Graduate School of Education and Psychology, where he holds the M. Norvel and Helen Young Endowed Chair in Family Life.",
      sources: [
        {
          url: "",
          title: "The Graphic",
          date: "02/08/73",
          publisher: "Pepperdine University Archives",
          page: "",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "William Pereira",
    initials: "WP",
    photo: {
      path: "/assets/WilliamPereira.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/p271401coll15/id/17994/rec/6",
        title: "John Tyler, William Pereira, Mrs. Seaver on the Platform at the Dedication",
        date: "1971",
        publisher: "Pepperdine University Archives Photograph Collection",
        page: "",
      },
    },
    seeAlso: {
      people: [],
      events: ["720218A", "720401B"],
    },
    role: "other",
    position: "Architect",
    description: {
      text: "William Pereira was the architect responsible for designing Pepperdine's Malibu campus. It was Pereira's vision that has come to be called the most beautiful college campus in the country.\nPereira worked closely with president William Banowsky in the planning phases. Pereira and Banowsky so often coincided in their vision that they were sometimes jointly called \"the two Bills.\"\nBefore he got the Pepperdine job, Pereira had designed LACMA, the Theme Building at LAX, and the Geisel Library at UCSD. In fact, it was Pereira who drew up the master plan for Malibu, including the allocation of 138 acres for an institution of higher education.<1>\nPereira was an eccentric (Banowsky recalls him wearing a cape on a visit to Malibu),<2> but he was a world-renowned architect, even appearing on the cover of |Time| in 1963 in association with his plan for the city of Irvine, California.<3>",
      sources: [
        {
          url: "",
          title: "Baird",
          date: "2016",
          publisher: "Pepperdine University Press",
          page: "273-4",
        },
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "174",
        },
        {
          url: "",
          title: "Time",
          date: "9/6/63",
          page: "68ff.",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Walter Glass",
    initials: "WG",
    photo: {
      path: "/assets/WalterGlass.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/5360/rec/1",
        title: "Imprints 1973",
        date: "1973",
        publisher: "Pepperdine University Archives Yearbook Collection",
        page: "61",
      },
    },
    seeAlso: {
      people: ["Howard A. White", "William S. Banowsky"],
      events: ["720107A"],
    },
    role: "faculty",
    position: "Professor of P.E.",
    description: {
      text: "In 1972, Walter Glass was professor of physical education at Pepperdine. Glass and his wife Betty were among the earliest Pepperdiners to move to Malibu ahead of the opening of the campus in September 1972.<1> They lived off Trancas Canyon, near Howard and Maxcine White.\nDuring the construction of the Malibu campus, Glass raised and lowered the American flag that flew on the 100-foot flagpole below today's Brock House.<2> The Glasses were good friends with the Banowskys, and they were among the founding members of the Church of Christ of Malibu, the congregation now known as the University Church of Christ.\nGlass died in 2022 during the writing of |Quinquagenary|.",
      sources: [
        {
          url: "",
          title: "Banowsky",
          date: "2010",
          publisher: "Pepperdine University Press",
          page: "179-80",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP01/id/13525/rec/694",
          title: "The Malibu Times",
          date: "1/7/72",
          publisher: "Pepperdine University Archives",
          page: "10",
        },
      ],
    },
    gender: "M",
  },
  {
    name: "Gary Colson",
    initials: "GC",
    photo: {
      path: "/assets/GaryColson.png",
      caption: "",
      source: {
        url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/5360/rec/1",
        title: "Imprints 1973",
        date: "1973",
        publisher: "Pepperdine University Archives Yearbook Collection",
        page: "61",
      },
    },
    seeAlso: {
      people: ["William \"Bird\" Averitt"],
      events: ["720108B", "720224B", "720227A"],
    },
    role: "admin",
    position: "Basketball coach, AD",
    description: {
      text: "In 1972, Gary Colson served as both Pepperdine's athletic director and the coach of its men's basketball team.<1> In February, he was pulled from a flight after one of his players joked about taking the plane to Florida, a comment that was overheard by a stewardess and interpreted as a threat to hijack the plane.<2> As athletic director he oversaw an NCAA rule change allowing freshmen to play varsity basketball,<3> as well as the shuttering of the track program in February.<4>",
      sources: [
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PUYB01/id/5360/rec/1",
          title: "Imprints",
          date: "1973",
          publisher: "Pepperdine University Archives Yearbook Collection",
          page: "61",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/4213/rec/67",
          title: "The Graphic",
          date: "3/2/72",
          publisher: "Pepperdine University Archives",
          page: "1, 6",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/9881/rec/61",
          title: "The Graphic",
          date: "1/13/72",
          publisher: "Pepperdine University Archives",
          page: "3",
        },
        {
          url: "https://pepperdine.contentdm.oclc.org/digital/collection/PDNP05/id/4211/rec/67",
          title: "The Graphic",
          date: "3/2/72",
          publisher: "Pepperdine University Archives",
          page: "4",
        },
      ],
    },
    gender: "M",
  },
];

/*
{
  name: "",
  initials: "",
  photo: {
      path: "/assets/WilliamPereira.png",
      caption: "",
      source: {
        url: "",
        title: "",
        date: "",
        publisher: "",
        page: "",
      },
    },
  seeAlso: {
    people: [],
    events: [],
  },
  role: "",
  position: "",
  description: {
    text: "",
    sources: [
      {
        url: "",
        title: "",
        date: "",
        publisher: "",
        page: "",
      },
    ],
  },
  gender: "",
},
*/

export default people;