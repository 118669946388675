import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Theme.backgroundWhite};

  footer {
    flex-shrink: 0;
  }

  header {
    flex-shrink: 0;
  }

  main {
    flex: 1 0 auto;
    background: ${Theme.backgroundWhite};
    min-height: 80vh;
  }
`

const PageSkeleton = ({children, rest}) => {
  return (
  <Wrapper {...rest}>
    {children}
  </Wrapper>);
};

export default PageSkeleton;
