import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';
import HoverIcon from './HoverIcon';
import {
  FaBookReader,
  FaAppleAlt,
  FaLandmark,
  FaBasketballBall,
  FaUtensils,
  FaCertificate,
  FaChurch,
  FaTools,
  FaMoneyBillWave,
  FaCalendarAlt,
  FaChalkboardTeacher,
  FaHome,
  FaUmbrellaBeach,
  FaNewspaper,
  FaRepublican,
  FaBible,
  FaGraduationCap,
  FaLock,
  FaToilet,
  FaVoteYea,
  FaUserFriends,
  FaPalette,
} from 'react-icons/fa';

const EventWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  width: 23rem;
  background-color: ${Theme.backgroundWhite};

  @media only screen and (min-width: 700px) {
    &:hover {
      .circle {
        background-color: ${Theme.secondary};
      }
    }
  }

  &:active {
    .card {
      box-shadow: inset ${Theme.boxShadow};
      border: none;
    }
  }

  .card {
    height: 4rem;
    width: 18rem;
    max-width: 75vw;
    padding: 0.5rem;
    margin-left: 2rem;
    
    background-color: ${Theme.white};
    border-radius: ${Theme.borderRadius};
    border: 1px solid ${Theme.black};
    cursor: pointer;

    @media only screen and (min-width: 700px) {
      &:hover {
        background-color: ${Theme.pLightBlue};
      }
    }
  }
  
  .title {
    max-height:2rem;
    font-size: ${Theme.fontSize};
    color: ${Theme.pBlue};
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    @media only screen and (min-width: 700px) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  p {
    height: 1rem;
    font-size: ${Theme.fontSize};
    color: ${Theme.mediumGray};
    font-weight: normal;
    font-style: italic;
  }

  .circle {
    border-radius: 50%;
    border: 0.5rem solid ${Theme.secondary};
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    @media only screen and (min-width: 700px) {
      &:hover {
        background-color: ${Theme.secondary};
      }
    }
  }

  .line {
    width: 0.5rem;
    height: 1rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    background-color: ${Theme.primary};
  }
  .left {
    padding-left: 0.5rem;
  }

  .detailsRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .iconRow {
    width: 4rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }
`

const Event = props => {
  let { onClick, paddingTop, event } = props;
  const getPadding = () => {
    if (paddingTop) return paddingTop + 1.125;
    else return 1.25;
  }

  event = event == null ? {
    title: "Death at the Battle of the Bulge",
    date: new Date(),
    location: "Here, ST",
    description: "The event in question happened at a certain time and in a certain place",
    photo: "",
  } : event;

  const iconMapping = {
    academics: <FaAppleAlt key="1"/>,
    administration:	<FaLandmark key="2" />,
    arts: <FaPalette key="2.5" />,
    athletics:	<FaBasketballBall key="3" />,
    cafeteria:	<FaUtensils key="4" />,
    cheerleading:	<FaCertificate key="5" />,
    church:	<FaChurch key="6" />,
    construction:	<FaTools key="7" />,
    donors:	<FaMoneyBillWave key="8" />,
    events:	<FaCalendarAlt  key="9" />,
    faculty:	<FaChalkboardTeacher key="10" />,
    housing:	<FaHome key="11" />,
    library: <FaBookReader key="21" />,
    Malibu:	<FaUmbrellaBeach key="12" />,
    newspapers:	<FaNewspaper key="13" />,
    politics:	<FaRepublican key="14" />,
    religion:	<FaBible key="15" />,
    "graduate schools":	<FaGraduationCap key="16" />,
    security:	<FaLock key="17" />,
    sewer:	<FaToilet key="18" />,
    "student government":	<FaVoteYea key="19" />,
    "student life":	<FaUserFriends key="20"/>,
  };

  const formatDate = str => {
    let noDay = str.slice(4);
    let wComma = noDay.slice(0, 6) + ',' + noDay.slice(6);
    if (wComma[4] === '0') {
      wComma = wComma.slice(0, 4) + wComma.slice(5);
    }
    return wComma;
  }

  return (
    <EventWrapper>
      <div className="left">
        <div className="line" style={{height:`${getPadding()}rem`}} />
        <div className="circle" onClick={onClick} />
        <div className="line" />
      </div>
      <div className="card" onClick={onClick}>
        <div className="title">
          {event.title} 
        </div>
        <div className="detailsRow">
          {formatDate(event.date.toDateString())}
          <span className="iconRow">
            {event.tags.map(t => <HoverIcon icon={iconMapping[t]} hoverText={t} key={t} color={Theme.primary}/>)}
          </span>
        </div>
      </div>
    </EventWrapper>
  )
}

export default Event;