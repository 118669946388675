import React from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';
import { FaTools } from 'react-icons/fa';

const PageWrapper = styled.main`
  background: ${Theme.backgroundWhite};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: ${Theme.primary};
`

const UnderConstruction = props => {
  return (
    <PageWrapper>
      <h1>
        <FaTools />
        &nbsp;Coming Soon!&nbsp;
        <FaTools />
      </h1>
      <p>This page is currently under construction.</p>
    </PageWrapper>
  )
}

export default UnderConstruction;
