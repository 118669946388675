import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Theme from '../style/theme';
import people from '../data/people';
// import events from '../data/events';
import Description from '../components/Description';
import SeeAlso from '../components/SeeAlso';
import Breadcrumbs from '../components/Breadcrumbs';
import ProfilePicture from '../components/ProfilePicture';
import NotFound from './NotFound';

const ProfileWrapper = styled.main`
  background: ${Theme.backgroundWhite};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: ${Theme.black};

  .lead-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;  
  }

  h1 {
    color: ${Theme.pBlue};
    margin-left: 0.5rem;
  }

  h4 {
    color: ${Theme.black};
    margin-left: 0.5rem;
  }

  .profile {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    margin: 0.5rem;
    box-shadow: ${Theme.boxShadow};
  }
`

const Profile = props => {
  const { breadcrumbs } = props;
  const { id } = useParams();

  const person = people.find(p => p.name === id);

  
  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0,0);
    // add all events the person is tagged in
    // events.forEach(e => {
    //   if (e.seeAlso.people.includes(person.name) && !seeAlsoToRender.events.includes(e.id)) {
      //     seeAlsoToRender.events.push(e.id);
      //   }
      // })
    }, [person]);
    
    let plainTextDescription;
    let truncatedDescription;
    let seeAlsoToRender;
    if (!person || !person.description || !person.seeAlso) {
      return <NotFound redirectPath="people" targetPage="to People"/>
    } else {
      plainTextDescription = person.description.text.replace(/\|/g, "");
      truncatedDescription = plainTextDescription.length < 420 ? plainTextDescription : plainTextDescription.substring(0,419);
      seeAlsoToRender = person.seeAlso;
      breadcrumbs.push(person.name);
    }

  return (
      <ProfileWrapper>
        <Helmet>
          <title>{person.name} in 1972</title>
          <meta name="description" content={truncatedDescription} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={person.name} />
          <meta name="twitter:description" content={truncatedDescription} />
          <meta property="og:title" content={person.name} />
          <meta property="og:description" content={truncatedDescription} />
          {Theme.showPictures && person.photo && 
            <meta name="twitter:image" content={"https://www.quinquagenary1972.com" + person.photo.path + "?1"} />}
          {Theme.showPictures && person.photo && <meta name="twitter:image:alt" content={person.photo.caption} />}
          {Theme.showPictures && person.photo && <meta property="og:image" content={"https://www.quinquagenary1972.com" + person.photo.path} />}
        </Helmet>
        <Breadcrumbs pages={breadcrumbs} />
        <div className="a">
          <div className="a1"/>
          <div className="a2"/>
          <div className="a3"/>
        </div>
        <div className="b">
          <div className="b1"/>
          <div className="b2"/>
          <div className="b3"/>
        </div>
        <div className="c">
          <div className="c1"/>
          <div className="c2"/>
          <div className="c3"/>
        </div>
        <div className="lead-row">
          {person.photo && Theme.showPictures && <ProfilePicture photo={person.photo} name={person.name}/>}
          <div>
            <h1>{person.name}</h1>
            <h4>{person.position}</h4>
          </div>
        </div>
        <Description description={person.description} />
        <SeeAlso data={seeAlsoToRender} />
      </ProfileWrapper>
  )
}

export default Profile;