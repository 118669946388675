import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../style/theme';

const NavWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  background-color: ${Theme.primary};
  height: 24px;

  li {
    display: inline;
  }

  li a {
    color: ${Theme.white};
    text-align: center;
    padding: 2px 12px;
    text-decoration: none;
  }

  @media only screen and (min-width: 700px) {
    li a:hover {
      background-color: ${Theme.accent};
    }
  }
`

const Navbar = () => {
  return (
    <header>
      <NavWrapper>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/timeline">Timeline</Link></li>
        <li><Link to="/people">People</Link></li>
        <li><Link to="/stories">Stories</Link></li>
        <li><Link to="/about">About</Link></li>
      </NavWrapper>
    </header>
  )
}

export default Navbar;
