import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../style/theme';
import Search from '../components/Search';

const PageWrapper = styled.main`
  background: ${Theme.backgroundWhite};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;

  .hero {
    /* filter: blur(1px); */
    width: 100%;
  }

  .logo {
    text-align: center;
    position: ${Theme.showPictures && "absolute"};
    font-size: 4vw;
    top: ${Theme.showPictures && "25%"};
    right: ${Theme.showPictures && "50%"};
    transform: ${Theme.showPictures && "translate(50%,-50%)"};
    text-shadow: 1px 1px ${Theme.white};
    cursor: pointer;
  }
  h1, p {
    position: relative;
  }
  .orangeBlock {
    background: ${Theme.pOrange};
    max-width: 30rem;
    width: 80%;
    text-align: center;
    margin-top: 1rem;
    border-radius: 3px;
    h2, h3 {
      font-weight: 400;
      color: white;
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
`;

const FlexCol = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  max-width: 30rem;

  a {
    margin-bottom: 1rem;
  }
`;

const LinkTileWrapper = styled.div`
  // background and shadow
  box-shadow: ${Theme.boxShadow};
  background-image: ${props => props.img && "url(" + props.img + ")"};
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${Theme.accent};

  // size and space
  /* height: 10rem; */
  min-width: 12.5rem;
  margin: 1rem;

  @media only screen and (max-width: 500px) {
    min-width: 9rem;
  }

  // layout
  display: flex;
  justify-content: center;
  align-items: center;

  // text
  font-size: 2rem;
  font-family: 'Montserrat';
  text-decoration: none;
  font-weight: 700;
  text-shadow: 1px 1px ${Theme.primary};
  color: ${Theme.white};
  height: ${props => props.img ? "10rem" : "0"};
  cursor: pointer;

  &:hover {
    filter: brightness(85%);
  }

  &:active {
    box-shadow: inset ${Theme.boxShadow};
  }
`;

const LinkTile = props => {
  if (!["http://localhost:3000/", "https://www.quinquagenary1972.com", "https://www.quinquagenary1972.com/"].includes(window.location.href)) {
    console.log("window.location.href:", window.location.href, "Theme.baseURL:", Theme.baseURL, "process.env.PUBLIC_URL", process.env.PUBLIC_URL);
    return <div></div>;
  } else {
    return <LinkTileWrapper {...props} />
  }
}

const Home = () => {
  const navigate = useNavigate();

  const goTo = uri => navigate(uri);

  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <PageWrapper>
      <Helmet>
        <title>Quinquagenary: 50 years of Pepperdine Malibu</title>
        <meta name="description" content="A celebration of the fiftieth anniversary of Pepperdine's Malibu campus." />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Quinquagenary: 50 years of Pepperdine Malibu"
        />
        <meta
          name="twitter:description"
          content="A celebration of the fiftieth anniversary of Pepperdine's Malibu campus."
        />
        <meta name="twitter:image" content={"https://www.quinquagenary1972.com/50512.png"} />
        <meta name="twitter:image:alt" content="" />
        <meta
          property="og:title"
          content="Quinquagenary: 50 years of Pepperdine Malibu"
        />
        <meta
          property="og:description"
          content="A celebration of the fiftieth anniversary of Pepperdine's Malibu campus."
        />
        <meta property="og:image" content={"https://www.quinquagenary1972.com/50512.png"} />
      </Helmet>
      <div>
        {Theme.showPictures && <img src="assets/MurchisonScaife.png" alt="homepage hero" className="hero" />}
        <div className="logo" onClick={() => goTo("/timeline")}>
          <h1>Quinquagenary</h1>
        </div>
      </div>
      <div className="orangeBlock">
        <h2>A <span><strong>1972</strong></span> Retrospective:</h2>
        <h3>Fifty years of Pepperdine Malibu</h3>
      </div>
      <FlexCol>
        <LinkTile onClick={() => goTo("/timeline")} img={Theme.showPictures && "assets/CentralCampus.png"}>Timeline</LinkTile>
        <FlexRow>
          <LinkTile onClick={() => goTo("/people")} img={Theme.showPictures && "assets/ScaifeGroup.png"}>People</LinkTile>
          <LinkTile onClick={() => goTo("/stories")} img={Theme.showPictures && "assets/PereiraTower.png"}>Stories</LinkTile>
        </FlexRow>
        <Search />
      </FlexCol>
    </PageWrapper>
  )
}

export default Home;
