import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Event from './Event';
import Tick from './Tick';
import Focus from './Focus';

const TimelineWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
`

const Timeline = props => {
  let { events, filterByTag } = props;
  const [focus, setFocus] = useState(null);
  const [eventsToRender, setEventsToRender] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const tag = searchParams.get('tag');

  const escFunction = evt => {
    if (evt.keyCode === 27) {
      setFocus(null);
    }
  }
  
  // handle key listener
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [])

  // handle events and ticks
  useEffect(() => {
    if (tag != null) {
      events = events.filter(e => e.tags.includes(tag));
    }
    events.sort((a,b) => a.date - b.date);
    // console.log("got these events to render", events);


    const getStartDay = date => {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      return new Date(year, month, day);
    }

    const getEndDay = date => {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      return new Date(year, month, Math.abs(day + 1));
    }

    const getStartMonth = date => {
      const year = date.getFullYear();
      const month = date.getMonth();
      return new Date(year, month);
    }

    const getEndMonth = date => {
      const year = date.getFullYear();
      const month = date.getMonth();
      return new Date(year, Math.abs(month + 1));
    }

    const getStartYear = date => {
      const year = date.getFullYear();
      return new Date(Math.abs(year - 1), 0);
    }

    const getEndYear = date => {
      const year = date.getFullYear();
      return new Date(Math.abs(year + 1), 0);
    }

    const getDecadeStart = date => {
      const year = date.getFullYear();
      const s = year.toString();
      return new Date(Math.abs(year - Number(s[s.length - 1])), 0)
    }

    const getDecadeEnd = date => {
      const year = date.getFullYear();
      const s = year.toString();
      return new Date(Math.abs(year + 10 - Number(s[s.length - 1])), 0);
    }

    const getCenturyStart = date => {
      const year = date.getFullYear();
      const s = year.toString();
      // console.log(`Math.abs(Number(s.slice(0,2) + "00"): ${Math.abs(Number(s.slice(0,2) + "00"))}`);
      return new Date(Math.abs(Number(s.slice(0,2) + "00")), 0);
    }

    const getCenturyEnd = date => {
      const year = date.getFullYear();
      const s = year.toString();
      return new Date(Math.abs(Number(s.slice(0,2)*100 + 100)), 0);
    }

    const addTicks = (eventList) => {
      const MONTH = 2629800000;
      const YEAR = 31557600000;
      const DECADE = 315576000000;
      const CENTURY = 3155760000000;
      // const MILLENNIUM = 31557600000000;
      const span = Math.abs(eventList[eventList.length - 1].date - eventList[0].date)
      const ticks = [];
      
      if (span < MONTH) { // less than month
        // console.log("less than month");
        let firstTickDate = getStartDay(eventList[0].date);
        let lastTickDate = getEndDay(eventList[eventList.length - 1].date);
        // add day before first event
        ticks.push({event: false, date: firstTickDate, day: firstTickDate, first: true});

        // add day after last event
        ticks.push({event: false, date: lastTickDate, day: lastTickDate});

        // fill in intervening days
        let nextDay = new Date(firstTickDate.getFullYear(), firstTickDate.getMonth(), Math.abs(firstTickDate.getDate() + 1));
        while (nextDay < lastTickDate) {
          ticks.push({event: false, date: nextDay, day: nextDay});
          nextDay = new Date(nextDay.getFullYear(), nextDay.getMonth(), Math.abs(nextDay.getDate() + 1));
        }
      } else if (span <= YEAR) { // between month and year
        // console.log("less than year");
        let firstTickDate = getStartMonth(eventList[0].date);
        let lastTickDate = getEndMonth(eventList[eventList.length - 1].date);
        // add month before first event
        ticks.push({event: false, date: firstTickDate, month: firstTickDate});

        // add month after last event
        ticks.push({event: false, date: lastTickDate, month: lastTickDate});

        // fill in intervening months
        let nextMonth = new Date(firstTickDate.getFullYear(), Math.abs(firstTickDate.getMonth() + 1));
        while (nextMonth < lastTickDate) {
          ticks.push({event: false, date: nextMonth, month: nextMonth});
          nextMonth = new Date(nextMonth.getFullYear(), Math.abs(nextMonth.getMonth() + 1));
        }
      } else if (span <= DECADE) { // between year and decade
        // console.log("less than decade");
        let firstTickDate = getStartYear(eventList[0].date);
        let lastTickDate = getEndYear(eventList[eventList.length - 1].date);

        // add year before first event
        ticks.push({event: false, date: firstTickDate});

        // add year after last event
        ticks.push({event: false, date: lastTickDate});

        // fill in intervening years
        let nextYear = new Date(Math.abs(firstTickDate.getFullYear() + 1), 0);
        while (nextYear < lastTickDate) {
          ticks.push({event: false, date: nextYear});
          nextYear = new Date(Math.abs(nextYear.getFullYear() + 1), 0);
        }
      } else if (span <= CENTURY) { // between decade and century
        // console.log("less than century");
        let firstTickDate = getDecadeStart(eventList[0].date);
        let lastTickDate = getDecadeEnd(eventList[eventList.length - 1].date);
        // add decade before first event
        ticks.push({event: false, date: firstTickDate});

        // add decade after last event
        ticks.push({event: false, date: lastTickDate});

        // fill in intervening decades
        let nextDecade = new Date(Math.abs(firstTickDate.getFullYear() + 10), 0);
        while (nextDecade < lastTickDate) {
          ticks.push({event: false, date: nextDecade});
          nextDecade = new Date(Math.abs(nextDecade.getFullYear() + 10), 0);
        }
      } else { // more than century
        // console.log("more than century");
        let firstTickDate = getCenturyStart(eventList[0].date);
        let lastTickDate = getCenturyEnd(eventList[eventList.length - 1].date);
        // add century before first event
        ticks.push({event: false, date: firstTickDate});

        // add century after last event
        ticks.push({event: false, date: lastTickDate});

        // fill in intervening centuries
        let nextCentury = new Date(Math.abs(firstTickDate.getFullYear() + 100), 0);
        while (nextCentury < lastTickDate) {
          ticks.push({event: false, date: nextCentury});
          nextCentury = new Date(Math.abs(nextCentury.getFullYear() + 100), 0);
        }
      }

      ticks.forEach(t => events.push(t));
      events.sort((a,b) => a.date - b.date);
    }

    const isMidnight = (date) => {
      return date.getHours() == 0 && date.getMinutes() == 0 && date.getSeconds() == 0
    }

    if (events.length) {
      let last = events[0].date;
      let totalHeight = 30;
      let totalSpan = Math.abs(events[events.length - 1].date - events[0].date);

      addTicks(events);
      const finalEvents = [];
      const seenDates = [];

      for (let e of events) {
        // push midnight times to noon
        if (e.event && isMidnight(e.date)) {
          e.date = new Date(e.date.getTime() + 43200000);
        }

        // filter out duplicate date-times
        if (!seenDates.includes(e.date.getTime())) {
          finalEvents.push(e);
        }
        seenDates.push(e.date.getTime());
      }
      
      finalEvents.sort((a,b) => a.date - b.date);

      for (let e of finalEvents) {
        let diff = Math.abs(e.date - last);
        // console.log(`last ${last}\ntotalSpan ${totalSpan}\ndiff ${diff}\nratio ${diff/totalSpan}`);
        let ratio = diff/totalSpan;
        e.padding = totalHeight * ratio;
        // console.log(`id: ${e.id}\nratio: ${e.ratio}\npadding: ${e.padding}`);
        last = e.date;
      }

      // console.log(`final events: ${events.map(e => e.date.toString() + '\n')}`);
      
      setEventsToRender(finalEvents);
    } else {
      setEventsToRender([]);
    }

  }, [events]);

  const closeModal = (evt) => {
    evt.preventDefault();
    if (evt.target === evt.currentTarget) {
      setFocus(null);
    }
  }

  return (eventsToRender.length > 0 ? 
    <TimelineWrapper>
      <div>
        {eventsToRender.length > 0 && eventsToRender.map(e => {
          if (e.event) return (
            <Event
              key={e.id}
              event={e}
              paddingTop={e.padding}
              onClick={() => setFocus(e)}
            />);
          else return (
            <Tick
              key={e.day ? e.day : e.month ? e.month : e.date}
              date={e.date}
              month={e.month}
              day={e.day}
              first={e.first}
              paddingTop={e.padding}
            />);
        })}
      </div>
      {focus &&
        <Focus
          event={focus}
          filterByTag={filterByTag}
          onClose={closeModal}
          setFocus={setFocus}
        />}
    </TimelineWrapper>
    : <p>No events to show.</p>
  )
}

export default Timeline;
