import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Theme from '../style/theme';
import Breadcrumbs from '../components/Breadcrumbs';
import CaptionedPhoto from '../components/CaptionedPhoto';
import Description from '../components/Description';
import Timeline from '../components/Timeline';
import SeeAlso from '../components/SeeAlso';
import events from '../data/events';
import stories from '../data/stories';
import NotFound from './NotFound';

const PageWrapper = styled.main`
  background: ${Theme.backgroundWhite};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  h1 {
    color: ${Theme.primary};
    margin: 0.25rem;
    line-height: 2.5rem;
  }
`

const StoryPage = props => {
  const { breadcrumbs } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  
  const story = stories.find(s => s.id === id);
  let eventsToRender, seeAlsoPeople, seeAlso;
  if (story) {
    eventsToRender = story.events
      .map(e => events
        .find(evt => evt.id === e))
      .filter(e => e != null);
  
    seeAlsoPeople = Array.from(
      new Set(eventsToRender
        .map(e => e.seeAlso.people)
        .flat()
      )
    );
  
    seeAlso = {
      events: [],
      people: seeAlsoPeople
    }

    breadcrumbs.push(story.title);
  }

  const handleFilterByTag = tag => navigate(`/timeline/?tag=${tag}`);

  
  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0,0);
  }, [story]);

  let plainTextDescription;
  let truncatedDescription;
  if (!story || !story.description) {
    return <NotFound redirectPath="stories" targetPage="to Stories" />
  } else {
  plainTextDescription = story.description.text.replace(/\|/g, "");
  truncatedDescription = plainTextDescription.length < 420 ? plainTextDescription : plainTextDescription.substring(0,419);
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>{story.title}</title>
        <meta name="description" content={truncatedDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={story.title} />
        <meta name="twitter:description" content={truncatedDescription} />
        <meta property="og:title" content={story.title} />
        <meta property="og:description" content={truncatedDescription} />
        <meta property="og:type" content="article" />
        {Theme.showPictures && story.photo && <meta name="twitter:image" content={"https://www.quinquagenary1972.com" + story.photo.path + "?2"} />}
        {Theme.showPictures && story.photo && <meta name="twitter:image:alt" content={story.photo.caption} />}
        {Theme.showPictures && story.photo && <meta property="og:image" content={"https://www.quinquagenary1972.com" + story.photo.path} />}
      </Helmet>
      <Breadcrumbs pages={breadcrumbs} />
      <h1>{story.title}</h1>
      {Theme.showPictures && <CaptionedPhoto photo={story.photo} />}
      <Description description={story.description} />
      <Timeline events={eventsToRender} filterByTag={handleFilterByTag} />
      <div className="a">
        <div className="a1"/>
        <div className="a2"/>
        <div className="a3"/>
      </div>
      <div className="b">
        <div className="b1"/>
        <div className="b2"/>
        <div className="b3"/>
      </div>
      <div className="c">
        <div className="c1"/>
        <div className="c2"/>
        <div className="c3"/>
      </div>
      <SeeAlso data={seeAlso} />
    </PageWrapper>
  )
}

export default StoryPage
