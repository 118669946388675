import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Theme from '../style/theme';
import Search from '../components/Search';
import ResultsList from '../components/ResultsList';
import Breadcrumbs from '../components/Breadcrumbs';

const PageWrapper = styled.main`
  background: ${Theme.backgroundWhite};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;
`;

const SearchResults = props => {
  const { breadcrumbs } = props;
  const [searchParams] = useSearchParams();
  const query = searchParams.get('tag');

  return (
    <PageWrapper>
      <Breadcrumbs pages={breadcrumbs} />
      <Search query={query} />
      <ResultsList query={query} />
    </PageWrapper>
  )
}

export default SearchResults;