import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Theme from '../style/theme';
import events from '../data/events';
import FilterBar from '../components/FilterBar';
import Timeline from '../components/Timeline';
import Breadcrumbs from '../components/Breadcrumbs';
import { monthList } from '../utilities';

const HomeWrapper = styled.main`
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 100%;

  h1 {
    max-width: 100%;
    text-align: center;
  }
`

const TimelinePage = props => {
  const { breadcrumbs } = props;
  const [searchParams] = useSearchParams();
  const tagParam = searchParams.get('tag');

  const events1972 = events
    .filter(e => e.date >= new Date("1/1/1972 00:00:00"))
    .filter(e => e.date <= new Date("1/1/1973 00:00:00"));
  const tags1972 = [...new Set(events1972
      .map(e => e.tags)
      .flat())]
    .sort((a,b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  const locations1972 = [...new Set(events1972
      .map(e => e.location)
      .flat())]
    .sort((a,b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  const [locationFilter, setLocationFilter] = useState();
  const [monthFilter, setMonthFilter] = useState();
  const [tagFilter, setTagFilter] = useState(tagParam);
  const [refresh, setRefresh] = useState(0);

  const applyLocationFilter = event => !locationFilter || locationFilter === event.location;
  const applyMonthFilter = event => !monthFilter || parseInt(monthFilter) === event.date.getMonth();
  const applyTagFilter = event => !tagFilter || event.tags.includes(tagFilter);

  const clearFilters = () => {
    setLocationFilter();
    setMonthFilter();
    setTagFilter();
    setRefresh(refresh + 1);
  }

  const _filteredEvents = events1972
    .filter(applyLocationFilter)
    .filter(applyMonthFilter)
    .filter(applyTagFilter);

  // scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <main style={{
      backgroundColor: Theme.backgroundWhite,
      display: "flex",
      justifyContent: "center"
    }}>
      <HomeWrapper>
        <Helmet>
          <title>1972: A Pepperdine Timeline</title>
          <meta name="description" content="What was happening at Pepperdine the year its Malibu campus opened in 1972? Athletics, arts, academics, construction, politics and more!" />
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:title"
            content="1972: A Pepperdine Timeline"
          />
          <meta
            name="twitter:description"
            content="What was happening at Pepperdine the year its Malibu campus opened in 1972? Athletics, arts, academics, construction, politics, and more!"
          />
          <meta name="twitter:image" content={"https://www.quinquagenary1972.com/50512.png"} />
          <meta name="twitter:image:alt" content="" />
          <meta
            property="og:title"
            content="1972: A Pepperdine Timeline"
          />
          <meta
            property="og:description"
            content="What was happening at Pepperdine the year its Malibu campus opened in 1972? Athletics, arts, academics, construction, politics, and more!"
          />
          <meta property="og:image" content={"https://www.quinquagenary1972.com/50512.png"} />
        </Helmet>
        <Breadcrumbs pages={breadcrumbs} />
        <h1>{monthList[monthFilter] ?? ""} 1972
          {tagFilter ? ` ${tagFilter} ` : (locationFilter ? " ": ": A Timeline")}
          {locationFilter && ("in " + locationFilter)}</h1>
        <FilterBar
          eventCount={_filteredEvents.length}
          setLocationFilter={setLocationFilter}
          setMonthFilter={setMonthFilter}
          setTagFilter={setTagFilter}
          clearFilters={clearFilters}
          tags={tags1972}
          locations={locations1972}
        />
        <Timeline
          events={_filteredEvents}
          filterByTag={setTagFilter}
        />
      </HomeWrapper>
    </main>
  )
}

export default TimelinePage;