import React, { useState } from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';
import CompactPerson from './CompactPerson';

const DrawerWrapper = styled.div`
  border-radius: ${Theme.borderRadius};
  background-color: ${Theme.pLightBlue};

  .rubric {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 18rem;
    border-radius: ${Theme.borderRadius};
    cursor: pointer;

    h2 {
      padding: 0.25rem 1rem;
    }

    span {
      font-size: 1.5rem;
      padding: 0.25rem 1rem;
      color: ${Theme.white};
    }

    &.open {
      background-color: ${Theme.pLightBlue};

      h2 {
        color: ${Theme.primary};
      }
    }

    &.closed {
      background-color: ${Theme.primary};

      h2 {
        color: ${Theme.white};
      }
    }

    @media only screen and (min-width: 700px) {
      &:hover {
        background-color: ${Theme.accent};
      }
    }
  }
  

  .drawer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: ${Theme.borderRadius};
    
    &.open {
      display: flex;
      background-color: ${Theme.pLightBlue};
    }
  
    &.closed {
      display: none;
    }
  }

`;

const Drawer = props => {
  const { openDrawer, setOpenDrawer, index } = props;
  const { data, title } = props.data;

  const toggleDrawer = () => {
    openDrawer === index
      ? setOpenDrawer(null)
      : setOpenDrawer(index);
  }
  
  return (
    <DrawerWrapper>
      <div className={openDrawer === index ? "rubric open" : "rubric closed"} onClick={toggleDrawer}>
        <h2>
          {title}
        </h2>
        <span>
          {openDrawer === index ? '–' : '+'}
        </span>
      </div>
      <div className={openDrawer === index ? "drawer open" : "drawer closed"}>
        {data.map(p => (<CompactPerson person={p} key={p.name} />))}
      </div>
    </DrawerWrapper>
  )
}

const AccordionWrapper = styled.div`
  border-radius: ${Theme.borderRadius};
  margin-bottom: 1rem;
`;

const Accordion = props => {
  const { data } = props;
  const [openDrawer, setOpenDrawer] = useState(0);

  return (
    <AccordionWrapper>
      {data.map((drawer, i) => (
        <Drawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          data={drawer}
          key={i}
          index={i}
        />))}
    </AccordionWrapper>
  )
}

export default Accordion;
